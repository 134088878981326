import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { Switch, Route } from "react-router";
import { Authenticator, Greetings } from "aws-amplify-react";

import Routes, { paths } from "./routes";
import LoginTheme from "./LoginTheme";
import wabcoLogo from "./assets/wabcoLogo.png";
// import jetpackLogoOnly from "./assets/jetpacklogoonly.png";
// import jetpackFullLogo from "./assets/jetpackfulllogo.png";
import NavigationMenu from "./components/NavigationMenu/NavigationMenu";
import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import SendToken from "./components/Login/SendToken";
import actions from "./actions/index";

const { Content, Sider } = Layout;

const RoutesToApp = ({ authState, user }) => {
  if (authState === "signedIn" || user != null) {
    return <Routes />;
  }
  return null;
};

function isLoginPath(location) {
  return (
    location && location.pathname && location.pathname.startsWith("/login")
  );
}

class AppLayout extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      collapsed: true,
      loginPath: isLoginPath(location)
    };
  }

  componentDidUpdate(prevProps) {
    const {
      availableCustomers: prevCustomers,
      isConnected: prevIsConnected,
      location: prevLocation
    } = prevProps;
    const {
      availableCustomers,
      selectCustomer,
      selectedCustomer,
      fetchCustomer,
      location,
      isConnected
    } = this.props;
    const shouldSelectDefaultCustomer =
      availableCustomers !== prevCustomers &&
      availableCustomers.length > 0 &&
      !selectedCustomer;
    if (shouldSelectDefaultCustomer) {
      selectCustomer(availableCustomers[0]);
    }
    if (!isLoginPath(location) && isLoginPath(prevLocation)) {
      this.setState({ loginPath: false });
    }
    if (isConnected && !prevIsConnected) {
      fetchCustomer();
    }
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  pageTitle = () => {
    const {
      location: { pathname: pathName }
    } = this.props;
    const pathKey = Object.keys(paths).find(
      keyPath => paths[keyPath].path === pathName
    );
    return paths[pathKey] ? paths[pathKey].title : "";
  };

  onAuthStateChanged = (authState, data) => {
    if (authState === "signedIn") {
      this.props.loginSuccess(data);
    }
  };

  toggleCollapse = () => {
    const { collapsed } = this.state;
    return this.setState({ collapsed: !collapsed });
  };

  render() {
    const { collapsed, loginPath } = this.state;
    return (
      <Layout style={{ height: "100vh" }}>
        <Sider
          style={{
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
            zIndex: 10
          }}
          collapsible
          theme="light"
          collapsed={collapsed}
          onCollapse={this.toggleCollapse}
          trigger={null}
        >
          <Link to={paths.home.path}>
            <img
              src={wabcoLogo}
              alt="logo"
              style={{
                width: collapsed ? 50 : 100,
                margin: collapsed ? "40px 15px" : "40px",
                marginBottom: 24
              }}
            />
          </Link>
          <NavigationMenu />
        </Sider>
        <Layout>
          <HeaderMenu
            toggleCollapse={this.toggleCollapse}
            collapsed={collapsed}
            title={this.pageTitle()}
          />
          <Content
            style={{
              height: "calc(100vh - 64px)"
            }}
          >
            {!loginPath && (
              <Authenticator
                hide={[Greetings]}
                theme={LoginTheme}
                onStateChange={(authState, data) =>
                  this.onAuthStateChanged(authState, data)
                }
              >
                <RoutesToApp {...this.props} />
              </Authenticator>
            )}
            <Switch>
              <Route exact path="/login" component={SendToken} />
              <Route exact path="/login/:token" component={SendToken} />
              <Route exact path="/login/:env/:token" component={SendToken} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
    // return <Route exact path="/" component={() => null} />;
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    isConnected: !!user.user,
    user: user.user,
    processing: user.processing,
    availableCustomers: state.customer.customers.items,
    selectedCustomer: state.customer.selectedCustomer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: () => dispatch(actions.user.retrieveSession()),
    fetchCustomer: () => dispatch(actions.customer.getCustomer()),
    loginSuccess: user => dispatch(actions.auth.authUserSuccess(user)),
    selectCustomer: customer =>
      dispatch(actions.customer.selectCustomer(customer))
    // checkLoggedIn: () => dispatch(actions.auth.checkUserLoggedIn())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
