// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Empty, Spin } from "antd";

import actions from "../../actions/index";
import TruckCouplingTable from "../TruckCouplingTable/TruckCouplingTable";

class TruckHistoricCoupling extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchCouplings } = this.props;
    fetchCouplings();
  }

  componentDidUpdate(prevProps) {
    const { fetchCouplings, selectedCustomerCode } = this.props;
    const { selectedCustomerCode: prevSelectedCustomerCode } = prevProps;
    if (prevSelectedCustomerCode !== selectedCustomerCode) {
      fetchCouplings();
    }
  }

  render() {
    const { loading, trucks } = this.props;
    if (loading) {
      return (
        <div style={{ textAlign: "center", marginTop: 150 }}>
          <Spin />
        </div>
      );
    }
    if (!trucks) {
      return (
        <div
          style={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <Empty description="No data currently available 🤕" />
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: "white" }}>
        <Row type="flex">
          <Col
            span={24}
            style={{
              height: "calc(100vh - 64px)",
              overflowY: "scroll",
              padding: 16
            }}
          >
            <Row>
              <TruckCouplingTable trucks={trucks} />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { truckCouplings } = state.trucks;
  return {
    trucks: truckCouplings.items,
    loading: truckCouplings.loading,
    selectedCustomerCode: state.customer.selectedCustomer
      ? state.customer.selectedCustomer.fleetCode
      : null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCouplings: () => dispatch(actions.coupling.getTruckHistoricCoupling())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TruckHistoricCoupling);
