import { API } from "aws-amplify";
import actionTypes from "./actionTypes";
import cleanUrl from "../helpers/cleanUrl";

function getMatchingsRequest() {
  return {
    type: actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_REQUEST
  };
}

function getMatchingsSuccess(matchings) {
  return {
    type: actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_SUCCESS,
    matchings
  };
}

function getMatchingsFailure(error) {
  return {
    type: actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_FAILURE,
    error
  };
}

/* eslint-disable no-await-in-loop */
async function loopApi(urlPath) {
  let lastKey;
  let results = [];
  do {
    const url = `${urlPath}${
      lastKey ? `?firstkey=${JSON.stringify(lastKey)}` : ""
    }`;
    // TODO Make the loop work
    const cleanedUrl = cleanUrl(url);
    const apiResults = await API.get("TruckTrailerMatching", cleanedUrl);
    const { scanResults, pagination } = apiResults;
    lastKey = pagination.lastKey;
    results = [].concat(results, scanResults);
  } while (lastKey);
  return results;
}

function getMatchings(fetchAll) {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedCustomer } = state.customer;
    dispatch(getMatchingsRequest());
    const urlPath = `matches/${selectedCustomer.fleetCode}/${
      fetchAll ? "all" : "today"
    }`;
    return loopApi(urlPath)
      .then(res => dispatch(getMatchingsSuccess(res)))
      .catch(err => dispatch(getMatchingsFailure(err)));
  };
}

function selectTruck(truckId) {
  return {
    type: actionTypes.TRUCK_TRAILER_MATCHING_SELECT_TRUCK,
    truckId
  };
}

function selectTrailer(trailerId) {
  return {
    type: actionTypes.TRUCK_TRAILER_MATCHING_SELECT_TRAILER,
    trailerId
  };
}

export default {
  getMatchings,
  selectTruck,
  selectTrailer
};
