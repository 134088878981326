import React from "react";
import { Layout, Icon } from "antd";

import UserMenu from "../UserMenu/UserMenu";
import FleetSelector from "../FleetSelector/FleetSelector";
import "./HeaderMenu.css";

const { Header } = Layout;

const styles = {
  header: {
    background: "#fff",
    padding: 0,
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    justifyContent: "space-between"
  },
  title: {
    margin: 0
  }
};
const HeaderMenu = ({ collapsed, toggleCollapse, title }) => (
  <Header style={styles.header}>
    <div className="header-menu-left">
      {toggleCollapse && ( // show only if we have toggleCollapse
        <Icon
          className="trigger"
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={toggleCollapse}
        />
      )}
      <h2 style={styles.title}>{title}</h2>
    </div>
    <div className="header-menu-right">
      <FleetSelector />
      <UserMenu />
    </div>
  </Header>
);

export default HeaderMenu;
