// @flow
export type LocationHistoryType = {
  timeInMs: number,
  deviceId: number,
  coordinates: [number, number]
};

function geoLocationMapping(locationHistoryPoint: any): LocationHistoryType {
  return {
    // TODO CHECK HOW TO GET THE PROPER LOCAL TIME
    timeInMs: locationHistoryPoint.Gpstime,
    deviceId: Number(locationHistoryPoint.VehicleId),
    coordinates: [
      Number(locationHistoryPoint.Lon),
      Number(locationHistoryPoint.Lat)
    ],
    speed: +locationHistoryPoint.Speed
  };
}

export default geoLocationMapping;
