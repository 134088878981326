import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import matchings from "./matchings";
import customer from "./customer";
import positionHistory from "./positionHistory";
import user from "./user";
import trucks from "./trucks";
import auth from "./auth";
import skySync from "./skySync";

export default history =>
  combineReducers({
    router: connectRouter(history),
    matchings,
    customer,
    positionHistory,
    user,
    trucks,
    auth,
    skySync
  });
