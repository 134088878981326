const STRINGS = {
  notMeasuredInTrip: "Not measured"
};

const LOCAL_STORAGE_KEYS = {
  SELECTED_CUSTOMER_LOCAL_STORAGE_KEY:
    "wabco_truck_trailer_matching_app_selectedCustomer"
};

export default {
  STRINGS,
  LOCAL_STORAGE_KEYS
};
