import produce from "immer";

import actionTypes from "../actions/actionTypes";

export const initialUserState = {
  processing: false,
  error: null,
  user: null
};

function authReducer(state = initialUserState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.USER_LOGOUT_SUCCESS:
        draft.user = null;
        draft.processing = false;
        break;
      case actionTypes.USER_LOGOUT_REQUEST:
        draft.processing = true;
        break;
      case actionTypes.USER_LOGOUT_FAILURE:
        draft.processing = false;
        draft.error = action.error;
        break;
      case actionTypes.USER_LOGIN_SUCCESS:
        draft.user = action.user;
        draft.processing = false;
        break;
      case actionTypes.USER_LOGIN_REQUEST:
        draft.processing = true;
        break;
      case actionTypes.USER_LOGIN_FAILURE:
        draft.error = action.error;
        draft.processing = false;
        break;
      default:
        return state;
    }
  });
}

export default authReducer;
