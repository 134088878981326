// @flow
import React, { Component } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { TripsLayer } from "@deck.gl/geo-layers";
import { WebMercatorViewport, MapController, MapView } from "@deck.gl/core";
import { connect } from "react-redux";
import { lineString } from "@turf/helpers";
import bbox from "@turf/bbox";
import ReactMapGL, { FlyToInterpolator } from "react-map-gl";

import type { MessageType } from "../../actions/message";
import colors from "../../helpers/colors";
import actions from "../../actions";

const turf = {
  lineString,
  bbox
};

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoia2FyaW1kb3VpZWIiLCJhIjoiY2piYzJvNG92MTg5cDM0cGIzdHhzNDAwOSJ9.vGw5SBOyAZulYkrXOQVgaw";
const mapboxStyles = {
  satellite: "mapbox://styles/mapbox/satellite-v9",
  regular: "mapbox://styles/amdj90/cjeiprtzh7x452smovoh6ehlw"
};

// Viewport settings
const initialViewState = {
  longitude: 2.35,
  latitude: 48.8,
  zoom: 3,
  pitch: 0,
  bearing: 0
};

type PropsType = {
  selectedtruckId: ?number,
  selectedTrailerDeviceId: ?number,
  fetchTruckLocationsHistory: (truckId: number) => void,
  fetchTrailerLocationsHistory: (trailerDeviceId: number) => void,
  truckLocationsHistory: ?[any],
  trailerLocationsHistory: ?[any],
  displayMessage: (messageType: MessageType, messageContent: string) => void,
  resetTrailerLocationsHistory: () => void,
  resetTruckLocationsHistory: () => void
};

type StateType = {
  truckPositionsLayer: ?any,
  trailerPositionsLayer: ?any,
  viewState: any
};

// DeckGL react component
class TruckTrailerMap extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      truckPositionsLayer: null,
      trailerPositionsLayer: null,
      subTrailsLayer: null,
      viewState: initialViewState
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selectedtruckId: prevtruckId,
      selectedTrailerDeviceId: prevTrailerDeviceId,
      truckLocationsHistory: prevTruckLocations,
      trailerLocationsHistory: prevTrailerLocations
    } = prevProps;
    const {
      selectedtruckId,
      fetchTruckLocationsHistory,
      truckLocationsHistory,
      selectedTrailerDeviceId,
      fetchTrailerLocationsHistory,
      trailerLocationsHistory,
      resetTrailerLocationsHistory,
      resetTruckLocationsHistory,
      currentTime
    } = this.props;
    if (selectedtruckId && selectedtruckId !== prevtruckId) {
      fetchTruckLocationsHistory(selectedtruckId);
    }
    if (!selectedtruckId && prevtruckId) {
      resetTruckLocationsHistory();
    }
    if (!selectedTrailerDeviceId && prevTrailerDeviceId) {
      resetTrailerLocationsHistory();
    }
    if (
      selectedTrailerDeviceId &&
      selectedTrailerDeviceId !== prevTrailerDeviceId
    ) {
      this.setState({ subTrailsLayer: null });
      fetchTrailerLocationsHistory(selectedTrailerDeviceId);
    }
    if (
      JSON.stringify(prevTruckLocations) !==
      JSON.stringify(truckLocationsHistory)
    ) {
      this.showTruckPositions();
    }
    if (
      JSON.stringify(trailerLocationsHistory) !==
      JSON.stringify(prevTrailerLocations)
    ) {
      this.showTrailerPositions();
    }
    const pathsHaveChanged =
      JSON.stringify(trailerLocationsHistory) !==
        JSON.stringify(prevTrailerLocations) ||
      JSON.stringify(prevTruckLocations) !==
        JSON.stringify(truckLocationsHistory);
    if (pathsHaveChanged) {
      this.fitMapOnPathsBounds();
    }

    if (
      (truckLocationsHistory || trailerLocationsHistory) &&
      currentTime &&
      currentTime !== prevProps.currentTime
    ) {
      this.computeSubTrailsLayer(
        truckLocationsHistory,
        trailerLocationsHistory,
        currentTime
      );
    }
  }

  fitMapOnPathsBounds = () => {
    const { truckLocationsHistory, trailerLocationsHistory } = this.props;
    const { viewState } = this.state;
    const viewStateWithWidthHeight = {
      ...viewState,
      /* eslint-disable no-underscore-dangle */
      width: this.mapRef._width,
      height: this.mapRef._height
      /* eslint-enable no-underscore-dangle */
    };
    const truckLocations =
      (truckLocationsHistory &&
        truckLocationsHistory.map(d => d.coordinates)) ||
      [];
    const trailerLocations =
      (trailerLocationsHistory &&
        trailerLocationsHistory.map(d => d.coordinates)) ||
      [];
    const allLocations = [].concat(truckLocations, trailerLocations);
    if (allLocations && allLocations.length > 1) {
      const line = turf.lineString(allLocations);
      const bounds = turf.bbox(line);
      const { longitude, latitude, zoom } = new WebMercatorViewport(
        viewStateWithWidthHeight
      ).fitBounds(
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]]
        ],
        {
          padding: {
            top: 50,
            /* eslint-disable no-underscore-dangle */
            bottom: Math.min(250, this.mapRef._height),
            left: 50,
            right: 50
          }
        }
      );
      const newViewState = {
        ...viewState,
        longitude,
        latitude,
        zoom,
        transitionDuration: 2500,
        transitionInterpolator: new FlyToInterpolator()
      };
      this.setState({ viewState: newViewState });
    }
  };

  getTrailerPositionsLayer = () => {
    const { trailerLocationsHistory, displayMessage } = this.props;
    if (!trailerLocationsHistory || trailerLocationsHistory.length === 0) {
      displayMessage("warning", "No location data found for trailer");
      return null;
    }
    const coordinates = trailerLocationsHistory.map(d => d.coordinates);
    const journeyLine =
      coordinates.length > 1
        ? turf.lineString(coordinates)
        : turf.lineString([coordinates[0], coordinates[0]]);
    const path = {
      type: "FeatureCollection",
      features: [journeyLine]
    };
    return new GeoJsonLayer({
      id: "trailer-positions-path-layer",
      data: path,
      getFillColor: colors.trailer,
      getLineColor: colors.trailer,
      pickable: true,
      lineWidthMinPixels: 1,
      lineWidthMaxPixels: 1,
      lineJointRounded: true,
      getElevation: 30
    });
  };

  getTruckPositionsLayer = () => {
    const { truckLocationsHistory, displayMessage } = this.props;
    if (!truckLocationsHistory || truckLocationsHistory.length === 0) {
      displayMessage("warning", "No location data found for truck");
      return null;
    }
    const coordinates = truckLocationsHistory.map(d => d.coordinates);
    const journeyLine = turf.lineString(coordinates);
    const path = {
      type: "FeatureCollection",
      features: [journeyLine]
    };
    return new GeoJsonLayer({
      id: "truck-positions-path-layer",
      data: path,
      getFillColor: colors.truck,
      getLineColor: colors.truck,
      pickable: true,
      lineWidthMinPixels: 1,
      lineWidthMaxPixels: 1,
      lineJointRounded: true,
      getElevation: 30
    });
  };

  computeSubTrailsLayer = (
    truckLocationsHistory,
    trailerLocationsHistory,
    currentTime
  ) => {
    const routes = [
      {
        path: trailerLocationsHistory
          .filter(p => p.timeInMs)
          .sort((a, b) => (a.timeInMs < b.timeInMs ? -1 : 1)),
        color: colors.trailer,
        width: 4
      },
      {
        path: truckLocationsHistory
          .filter(p => p.timeInMs)
          .sort((a, b) => (a.timeInMs < b.timeInMs ? -1 : 1)),
        color: colors.truck,
        width: 7
      }
    ].filter(r => r.path && r.path.length > 0);

    if (routes && routes.length > 0) {
      const startTime = Math.min(
        ...routes
          .map(r => r.path.map(t => t.timeInMs))
          .reduce((agg, array) => agg.concat(array), [])
      );
      const endTime = Math.max(
        ...routes
          .map(r => r.path.map(t => t.timeInMs))
          .reduce((agg, array) => agg.concat(array), [])
      );
      const currentTimeInSec = Math.round(currentTime.getTime() / 1000);
      const duration = endTime - startTime;
      const timeStep = Math.max(1, duration / 1000);
      const trailLength = Math.round((currentTimeInSec - startTime) / timeStep);
      const graphCurrTime = Math.round(
        (currentTimeInSec - startTime) / timeStep
      );
      const subTrailsLayer = new TripsLayer({
        id: "sub-trips-layer",
        data: routes,
        getPath: r =>
          r.path.map(d => [
            ...d.coordinates,
            Math.round((1 * (d.timeInMs - startTime)) / timeStep)
          ]),
        getTimestamps: r =>
          r.path.map((d, i) =>
            Math.round((1 * (d.timeInMs - startTime)) / timeStep)
          ),
        getColor: r => r.color,
        opacity: 0.9,
        widthMinPixels: 6,
        rounded: true,
        trailLength, // new Date().getTime(), // Math.round((currentTimeInSec - startTime)),
        currentTime: graphCurrTime,
        fp64: true
      });
      this.setState({ subTrailsLayer });
    } else {
      this.setState({ subTrailsLayer: null });
    }
  };

  onViewStateChange = ({ viewState }) => {
    this.setState({ viewState });
  };

  showTrailerPositions = () => {
    const trailerPositionsLayer = this.getTrailerPositionsLayer();
    this.setState({ trailerPositionsLayer });
  };

  showTruckPositions = () => {
    const truckPositionsLayer = this.getTruckPositionsLayer();
    this.setState({ truckPositionsLayer });
  };

  render() {
    const {
      truckPositionsLayer,
      trailerPositionsLayer,
      subTrailsLayer,
      viewState
    } = this.state;
    const layers = [truckPositionsLayer, trailerPositionsLayer, subTrailsLayer];
    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <DeckGL
          viewState={viewState}
          layers={layers}
          onViewStateChange={this.onViewStateChange}
          views={
            new MapView({
              id: "basemap",
              controller: {
                type: MapController
              }
            })
          }
        >
          <ReactMapGL
            reuseMaps
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing
            mapStyle={mapboxStyles.regular}
            ref={map => {
              this.mapRef = map;
            }}
          >
            {/* <Draggable bounds="parent" enableUserSelectHack={false}>
              <div className={classNames.matches}>
                <TruckMatches />
              </div>
          </Draggable> */}
          </ReactMapGL>
        </DeckGL>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { positionHistory } = state;
  return {
    truckLocationsHistory: positionHistory.truck.locationsHistory,
    trailerLocationsHistory: positionHistory.trailer.locationsHistory,
    selectedtruckId: state.matchings.selectedTruckId,
    selectedTrailerDeviceId: state.matchings.selectedTrailerId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTruckLocationsHistory: truckId =>
      dispatch(actions.positionHistory.getTruckPositionHistory(truckId)),
    fetchTrailerLocationsHistory: trailerDeviceId =>
      dispatch(
        actions.positionHistory.getTrailerPositionHistory(trailerDeviceId)
      ),
    resetTrailerLocationsHistory: () =>
      dispatch(actions.positionHistory.resetTrailerPositionHistory()),
    resetTruckLocationsHistory: () =>
      dispatch(actions.positionHistory.resetTruckPositionHistory()),
    displayMessage: (messageType, messageContent) =>
      dispatch(actions.message.displayMessage(messageType, messageContent))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckTrailerMap);
