import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Icon } from "antd";

import actions from "../../actions";

const { confirm } = Modal;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "100%"
  },
  content: {
    position: "absolute",
    top: "33%",
    display: "block"
  },
  iconContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    marginBottom: 12
  },
  icon: {
    fontSize: 88,
    marginLeft: "auto",
    marginRight: "auto",
    color: "gray"
  }
};
function TriggerSync() {
  const dispatch = useDispatch();
  function sendSkyRequest() {
    return dispatch(actions.skySync.skySync());
  }
  function showConfirm() {
    confirm({
      title: "Do you want to synchronize trucks status with Sky?",
      content: "This action cannot be cancelled",
      onOk() {
        sendSkyRequest();
      },
      onCancel() {
        // setShowConfirmModal(false);
      }
    });
  }
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.iconContainer}>
          <Icon type="cloud-sync" style={styles.icon} />
        </div>
        <Button icon="sync" onClick={showConfirm} type="primary">
          Click to send all trucks statuses to Sky
        </Button>
      </div>
    </div>
  );
}

export default TriggerSync;
