// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// import { Spin, Row, Col, Empty, Collapse, Icon } from "antd";
import { Spin, Row, Col, Empty } from "antd";
import crossfilter from "crossfilter2";
import moment from "moment";

import actions from "../../actions/index";
import TruckTrailerTable from "../TruckTrailerTable/TruckTrailerTable";
// import TruckTrailerFilterSwitch from "../TruckTrailerFilterSwitch/TruckTrailerFilterSwitch";
import TruckTrailerMap from "../TruckTrailerMap/TruckTrailerMap";
// import BrushBarChart from "../../charts/BrushBarChart/BrushBarChartComponent";
import type { MatchingType } from "../../mappings/matchings";
import TrailHistory from "../TrailHistory/TrailHistory";

/* const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
}; */

function orderCount(p) {
  return p.count;
}

function reduceInitial() {
  return {
    count: 0
  };
}

function reduceAdd(aggregator, element) {
  aggregator.count += 1;
  return aggregator;
}

function reduceRemove(p) {
  p.count -= 1;
  return p;
}

function getGroupingValues(cf) {
  const groupingValues = {
    all: cf.groups.all.value(),
    latestTripStartTime: cf.groups.latestTripStartTime.all(),
    latestTripEndTime: cf.groups.latestTripEndTime.all(),
    latestBestMatchAverageDistance: cf.groups.latestBestMatchAverageDistance.all(),
    latestTripDuration: cf.groups.latestTripDuration.all(),
    latestTripDistance: cf.groups.latestTripDistance.all(),
    tripCount: cf.groups.tripCount.all()
  };
  return groupingValues;
}

type PropsTypes = {
  loading: boolean,
  matchings: MatchingType[],
  selectedCustomerCode: Number,
  fetchMatchings: () => void
};

type StateType = {
  crossfilter: any,
  groupingValues: any,
  dateRange: ?[Date, Date],
  filters: any
};

class TruckTrailer extends Component<PropsTypes, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      crossfilter: null,
      groupingValues: null,
      dateRange: null,
      filters: {},
      currentTime: null
    };
  }

  componentDidMount() {
    const { fetchMatchings } = this.props;
    fetchMatchings();
  }

  componentDidUpdate(prevProps) {
    const { trucks, selectedCustomerCode, fetchMatchings } = this.props;
    const { selectedCustomerCode: prevSelectedCustomerCode } = prevProps;
    if (!prevProps.trucks && trucks && trucks.length && trucks.length > 0) {
      const cf = this.buildCrossFilter(trucks);
      const groupingValues = getGroupingValues(cf);
      return this.setState(
        {
          crossfilter: cf,
          groupingValues
        },
        () => {
          this.updateIdentity(cf);
          // this.filterConfirmedMatch(true);
        }
      );
    }
    if (prevSelectedCustomerCode !== selectedCustomerCode) {
      return fetchMatchings();
    }
    return null;
  }

  buildCrossFilter = trucks => {
    const trucksCrossfilter = { dim: {}, groups: {} };
    trucksCrossfilter.cf = crossfilter(trucks);
    trucksCrossfilter.dim.identity = trucksCrossfilter.cf.dimension(
      d => d.truckId
    );
    trucksCrossfilter.dim.latestTripStartTime = trucksCrossfilter.cf.dimension(
      d =>
        moment(d.latestTripStartTime)
          .startOf("hour")
          .toDate()
          .getTime()
    );
    trucksCrossfilter.dim.latestTripEndTime = trucksCrossfilter.cf.dimension(
      d =>
        moment(d.latestTripEndTime)
          .startOf("hour")
          .toDate()
          .getTime()
    );
    trucksCrossfilter.dim.latestBestMatchAverageDistance = trucksCrossfilter.cf.dimension(
      d => Math.round(10 * d.latestBestMatchAverageDistance) * 0.1
    );
    trucksCrossfilter.dim.latestTripDuration = trucksCrossfilter.cf.dimension(
      d => 10 * Math.round(0.1 * d.latestTripDuration)
    );
    trucksCrossfilter.dim.latestTripDistance = trucksCrossfilter.cf.dimension(
      d => Math.round(d.latestTripDistance)
    );
    trucksCrossfilter.dim.tripCount = trucksCrossfilter.cf.dimension(d =>
      d.trips ? d.trips.length : 0
    );
    trucksCrossfilter.groups.all = trucksCrossfilter.cf
      .groupAll()
      .reduce(reduceAdd, reduceRemove, reduceInitial);
    trucksCrossfilter.groups.latestTripStartTime = trucksCrossfilter.dim.latestTripStartTime
      .group()
      .reduce(reduceAdd, reduceRemove, reduceInitial)
      .order(orderCount);
    trucksCrossfilter.groups.latestTripEndTime = trucksCrossfilter.dim.latestTripEndTime
      .group()
      .reduce(reduceAdd, reduceRemove, reduceInitial)
      .order(orderCount);
    trucksCrossfilter.groups.latestBestMatchAverageDistance = trucksCrossfilter.dim.latestBestMatchAverageDistance
      .group()
      .reduce(reduceAdd, reduceRemove, reduceInitial)
      .order(orderCount);
    trucksCrossfilter.groups.latestTripDuration = trucksCrossfilter.dim.latestTripDuration
      .group()
      .reduce(reduceAdd, reduceRemove, reduceInitial)
      .order(orderCount);
    trucksCrossfilter.groups.latestTripDistance = trucksCrossfilter.dim.latestTripDistance
      .group()
      .reduce(reduceAdd, reduceRemove, reduceInitial)
      .order(orderCount);
    trucksCrossfilter.groups.tripCount = trucksCrossfilter.dim.tripCount
      .group()
      .reduce(reduceAdd, reduceRemove, reduceInitial)
      .order(orderCount);
    return trucksCrossfilter;
  };

  filter = (type, values, dimension) => {
    const { filters, crossfilter: cf } = this.state;
    const filterValues =
      filters && filters[dimension] && filters[dimension].values
        ? filters[dimension].values
        : null;
    if (filterValues !== values) {
      const newCf = this.filterCf(type, values, dimension, cf);
      const distributions = getGroupingValues(newCf);
      this.setState(
        {
          crossfilter: newCf,
          groupingValues: distributions,
          filters: Object.assign({}, filters, {
            [dimension]: { type, values, dimension }
          })
        },
        () => this.updateIdentity(newCf)
      );
    }
  };

  filterCf = (type, values, dimension, cf) => {
    switch (type) {
      case "range":
        if (values) {
          cf.dim[dimension].filter(d => {
            return values[0] <= d && d <= values[1];
          });
        } else {
          cf.dim[dimension].filter();
        }
        return cf;
      case "equality":
        if (values && values.length > 0) {
          cf.dim[dimension].filterFunction(d => values.includes(d));
        } else {
          cf.dim[dimension].filter();
        }
        return cf;
      default:
        return null;
    }
  };

  /* filterTimeFrame = dateTimeArray => {
    const minDate = moment(dateTimeArray[0])
      .toDate()
      .getTime();
    const maxDate = moment(dateTimeArray[1])
      .toDate()
      .getTime();
    this.filter("range", [minDate, maxDate], "matchLastTime");
  }; */

  updateIdentity = cf => {
    const { groupingValues } = this.state;
    const identity = cf.dim.identity.top(Infinity);
    return this.setState({
      groupingValues: Object.assign({}, groupingValues, { identity })
    });
  };

  onTimeChange = currentTime => {
    this.setState({ currentTime });
  };

  render() {
    const { loading, trucks } = this.props;
    const { filters, currentTime } = this.state;
    if (loading) {
      return (
        <div style={{ textAlign: "center", marginTop: 150 }}>
          <Spin />
        </div>
      );
    }
    if (!trucks) {
      // || !groupingValues) {
      return (
        <div
          style={{
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <Empty description="No data currently available 🤕" />
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: "white" }}>
        <Row type="flex">
          <Col
            span={12}
            style={{
              height: "calc(100vh - 64px)",
              overflowY: "scroll",
              padding: 16
            }}
          >
            <Row>
              <TruckTrailerTable
                trucks={trucks} // groupingValues.identity || []}
                onFilter={this.filter}
                filters={filters}
              />
            </Row>
          </Col>
          <Col span={12}>
            <TruckTrailerMap currentTime={currentTime} />
            <TrailHistory onTimeChange={this.onTimeChange} />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { trucks } = state.trucks;
  return {
    trucks: trucks.items,
    loading: trucks.loading,
    selectedCustomerCode: state.customer.selectedCustomer
      ? state.customer.selectedCustomer.fleetCode
      : null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMatchings: () => dispatch(actions.matchings.getMatchings(true))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckTrailer);
