// @flow

function cleanTruckCoupling(truckCouplings: any) {
  return {
    ...truckCouplings
    /*matchedTripId: matching.Matched_Trip_ID,
    numPlate: matching.Numplate,
    inTrip: mapInTripInfo(matching.info.InTrip),
    matchLastTime: matching.info.MatchLastTime,
    matchStartTime: matching.info.MatchStartTime,
    distance: matching.info.MatchLastOdo - matching.info.MatchStartOdo,
    durationInMinutes: Math.round(((new Date(matching.info.MatchLastTime)).getTime() - (new Date(matching.info.MatchStartTime)).getTime()) / (1000 * 60)),
    confirmedMatch: matching.info.ConfirmedMatch,
    matched: matching.info.Matched,
    type: matching.info.Type,
    confidence: +matching.info.confidenceV1,
    tripStartTime: matching.info.TripStartTime,
    tripEndTime: matching.info.TripLastTime,
    truckId: matching.info.VehicleIDNumPlate,
    truckNumplate: matching.Numplate,
    trailerId: matching.info.VehicleIDMatched,
    trailerNumPlate: matching.info.Matched,
    pathMedianDistance: +matching.MedianDistance,
    tripDistance: matching.info.TripLastOdo && matching.info.TripStartOdo ? matching.info.TripLastOdo - matching.info.TripStartOdo : null,
    highFreqTrailer: matching.highFreqTrailer,
    tripId: matching.info.TripUUID*/
  };
}

export default cleanTruckCoupling;
