import produce from "immer";

import actionTypes from "../actions/actionTypes";

const initialState = {
  trailer: {
    loading: false,
    error: null,
    locationsHistory: null
  },
  truck: {
    loading: false,
    error: null,
    locationsHistory: null
  }
};

export default function positionHistoryReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.POSITION_HISTORY_TRAILER_FETCH_REQUEST:
        draft.trailer.loading = true;
        break;
      case actionTypes.POSITION_HISTORY_TRAILER_FETCH_SUCCESS:
        draft.trailer.locationsHistory = action.locationsHistory;
        draft.trailer.loading = false;
        break;
      case actionTypes.POSITION_HISTORY_TRAILER_FETCH_FAILURE:
        draft.trailer.error = action.error;
        draft.trailer.loading = false;
        break;
      case actionTypes.POSITION_HISTORY_TRUCK_FETCH_REQUEST:
        draft.truck.loading = true;
        break;
      case actionTypes.POSITION_HISTORY_TRUCK_FETCH_SUCCESS:
        draft.truck.locationsHistory = action.locationsHistory;
        draft.truck.loading = false;
        break;
      case actionTypes.POSITION_HISTORY_TRUCK_FETCH_FAILURE:
        draft.truck.error = action.error;
        draft.truck.loading = false;
        break;
      case actionTypes.POSITION_HISTORY_TRAILER_RESET:
        draft.trailer = initialState.trailer;
        break;
      case actionTypes.POSITION_HISTORY_TRUCK_RESET:
        draft.truck = initialState.truck;
        break;
      default:
        return state;
    }
  });
}
