import * as d3 from "d3";
import Chart from "../Chart";

export default class CouplingSummaryChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg
      .append("g")
      .attr("class", "main")
      .attr(
        "transform",
        `translate(${this.props.margin.left},${this.props.height / 2})`
      );

    this.background = this.main.append("g").attr("class", "background");

    this.couplings = this.main.append("g").attr("class", "couplings");

    this.apiCalls = this.main.append("g").attr("class", "apiCalls");

    this.legend = this.main
      .append("g")
      .attr("class", "legend")
      .attr("opacity", 0);

    this.barHeight = 16;

    this.legendStart = this.legend
      .append("text")
      .attr("x", 0)
      .attr("y", this.barHeight)
      .attr("text-anchor", "start")
      .attr("font-size", 10);

    this.legendEnd = this.legend
      .append("text")
      .attr("x", this.props.width)
      .attr("y", this.barHeight)
      .attr("text-anchor", "end")
      .attr("font-size", 10);

    this.barHeight = 16;
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.couplings && state.couplings.length > 0) {
      this._drawChart(state);
    }
  }

  _drawChart(state) {
    const { start, end, type, trailerColorMap } = state;
    const couplingData = state.couplings.map(c => ({
      // start: getStart(c, type),
      start: type === "time" ? new Date(c.startTime * 1000) : c.startOdo,
      end: type === "time" ? new Date(c.endTime * 1000) : c.endOdo,
      numberOfTrips: c.numberOfTrips,
      trailerId: c.trailerID
    }));

    const apiCallData = state.apiCalls
      ? state.apiCalls
          .map(a => ({
            time: a.timeOfExecution,
            action: a.action,
            apiTriggerEvent: a.apiTriggerEvent,
            odo: a.lastOdoOfCoupling
          }))
          .sort((a, b) => (a.action > b.action ? 1 : -1))
      : null;

    const x = (type === "time" ? d3.scaleTime() : d3.scaleLinear())
      .range([0, this.props.width])
      .domain([start, end]);

    this.background
      .append("rect")
      .attr("x", 0)
      .attr("y", -this.barHeight / 2)
      .attr("width", this.props.width)
      .attr("height", this.barHeight)
      .attr("rx", 2)
      .attr("ry", 2)
      .style("fill", "lightgrey");

    const couplings = this.couplings.selectAll(".coupling").data(couplingData);

    const pathsEnter = couplings
      .enter()
      .append("rect")
      .attr("class", "coupling")
      .on("mouseover", d => this.highlight(d.trailerId))
      .on("mouseout", d => this.highlight(null));

    pathsEnter
      .merge(couplings)
      .transition()
      .duration(750)
      .attr("fill", d =>
        d.trailerId ? trailerColorMap[d.trailerId] : "#adadad"
      )
      .attr("x", d => x(d.start))
      .attr("y", -this.barHeight / 2)
      .attr("width", d => x(d.end) - x(d.start))
      .attr("height", this.barHeight)
      .attr("rx", 2)
      .attr("ry", 2);

    couplings.exit().remove();

    if (apiCallData && type === "time") {
      const apiCalls = this.apiCalls.selectAll(".apiCall").data(apiCallData);

      const circleEnter = apiCalls
        .enter()
        .append("circle")
        .attr("class", "apiCall")
        .on("mouseover", d => this.highlight(d.trailerId))
        .on("mouseout", d => this.highlight(null));

      circleEnter
        .merge(apiCalls)
        .transition()
        .duration(750)
        .attr("fill", d => colorCircle(d.action))
        .attr("cx", d => x(d.time))
        .attr("cy", d => barHeight(this, d.action))
        .attr("r", d => sizeCircle(d.action))
        .style("stroke", "white")
        .attr("stroke-width", 0);

      apiCalls.exit().remove();
    } else if (apiCallData && type === "odo") {
      const apiCalls = this.apiCalls.selectAll(".apiCall").data(apiCallData);

      const circleEnter = apiCalls
        .enter()
        .append("circle")
        .attr("class", "apiCall")
        .on("mouseover", d => this.highlight(d.trailerId))
        .on("mouseout", d => this.highlight(null));

      circleEnter
        .merge(apiCalls)
        .transition()
        .duration(750)
        .attr("fill", d => colorCircle(d.action))
        .attr("cx", d => x(d.odo))
        .attr("cy", d => barHeight(this, d.action))
        .attr("r", d => sizeCircle(d.action))
        .style("stroke", "white")
        .attr("stroke-width", 0);

      apiCalls.exit().remove();
    }

    this.legend.attr("opacity", 0);
    this.legendStart.text(state.startLabel);
    this.legendEnd.text(state.endLabel);
  }

  highlight(trailerId) {
    d3.selectAll(".coupling").attr("opacity", d =>
      !trailerId || d.trailerId === trailerId ? 1 : 0.2
    );
    this.legend.attr("opacity", trailerId ? 1 : 0);
  }
}

// function getStart(coupling, type, start, end) {

//   if (type === "time") {
//     if ((start > new Date(coupling.startTime * 1000)) && (end > new Date(coupling.endTime * 1000)) && (start < new Date(coupling.endTime * 1000))) {
//       return start.subtract(1, "minutes")
//     }

//     else {
//       return (new Date(coupling.startTime * 1000))
//     }
//   }
//   else {
//     return coupling.startOdo
//   }
// }

function colorCircle(action) {
  if (action === "Couple") {
    return "black";
  }
  if (action === "Uncouple") {
    return "white";
  }
  if (action === "Delete") {
    return "#e84343";
  }

  return "#4670f2";
}

function sizeCircle(action) {
  if (action === "Couple") {
    return 4;
  }
  if (action === "Uncouple") {
    return 2;
  }
  if (action === "Delete") {
    return 4;
  }

  return 2;
}

function barHeight(object, action) {
  if (action === "Couple") {
    return (object.barHeight / 2) * 0.7;
  }
  if (action === "Uncouple") {
    return (object.barHeight / 2) * 0.7;
  }
  if (action === "Delete") {
    return (object.barHeight / 2) * 2;
  }

  return (object.barHeight / 2) * 2;
}
