import matchings from "./matchings";
import message from "./message";
import positionHistory from "./positionHistory";
import user from "./user";
import validation from "./validation";
import coupling from "./coupling";
import customer from "./customer";
import auth from "./auth";
import skySync from "./skySync";

export default {
  auth,
  customer,
  matchings,
  message,
  positionHistory,
  user,
  validation,
  coupling,
  skySync
};
