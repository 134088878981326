// @flow
import React from "react";
import { Switch, Route, Redirect } from "react-router";

import Home from "./components/Home/Home";
import TruckTrailer from "./components/TruckTrailer/TruckTrailer";
import TruckHistoricCoupling from "./components/TruckHistoricCoupling/TruckHistoricCoupling";
import Validation from "./components/Validation/Validation";
import TriggerSync from "./components/TriggerSync/TriggerSync";

export const paths = {
  home: {
    path: "/",
    component: Home,
    title: "Home",
    icon: "home",
    hideFromMenu: true
  },
  truckTrailer: {
    path: "/truck-trailer",
    component: TruckTrailer,
    title: "Truck Live coupling",
    icon: "area-chart"
  },
  TruckHistory: {
    path: "/truck",
    component: TruckHistoricCoupling,
    title: "Truck Historic Coupling",
    icon: "align-right"
  },
  validation: {
    path: "/validation",
    component: Validation,
    title: "Validation",
    icon: "file-done"
  }
  // triggerSync: {
  //   path: "/trigger-sync",
  //   component: TriggerSync,
  //   title: "Synchronization",
  //   icon: "sync"
  // }
};

const routes = () => {
  return (
    <Switch>
      {Object.keys(paths).map(key => (
        <Route
          key={key}
          exact
          path={paths[key].path}
          component={paths[key].component}
        />
      ))}
      <Redirect to={paths.home.path} />
    </Switch>
  );
};

export default routes;
