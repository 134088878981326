// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Select } from "antd";
import TrailerHistoryChart from "../../charts/TrailerHistoryChart/TrailerHistoryChartComponent";
import TruckMatchesDropdown from "../TruckMatches/TruckMatchesDropdown";
import actions from "../../actions";
// import moment from "moment";

const styles = {
  history: {
    fontSize: "12px",
    position: "absolute",
    background: "rgba(255, 255, 255, 0.8)",
    color: "#000",
    width: "calc(100% - 20px)",
    zIndex: 9,
    // pointerEvents: 'none',
    borderRadius: "10px",
    bottom: 10,
    left: 10,
    padding: 16
  }
};

class TrailerHistory extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      hours: 24
    };
  }

  componentDidUpdate(preProps) {
    const { selectedTruckId, selectedTrailerId } = this.props;
    if (
      selectedTruckId !== preProps.selectedTruckId ||
      selectedTrailerId !== preProps.selectedTrailerId
    ) {
      this.setState({ hours: 24 });
    }
  }

  onPosChange = pos => {
    this.props.onTimeChange(pos.time);
  };

  onTripSelection = trip => {
    const { selectTrailerId } = this.props;
    if (trip.bestMatchTrailerId) {
      selectTrailerId(trip.bestMatchTrailerId);
    }
  };

  handleLastHoursChange = hours => {
    const {
      fetchTruckLocationsHistory,
      fetchTrailerLocationsHistory,
      selectedTruckId,
      selectedTrailerId
    } = this.props;
    fetchTruckLocationsHistory(selectedTruckId, hours);
    fetchTrailerLocationsHistory(selectedTrailerId, hours);
    this.setState({ hours });
  };

  render() {
    const {
      truckLocationsHistory,
      trailerLocationsHistory,
      selectedTruckDevice,
      selectedTrailerDevice,
      trips
    } = this.props;
    return (
      <div>
        {(selectedTruckDevice || selectedTrailerDevice) && (
          <div style={styles.history}>
            <Row type="flex" justify="space-between">
              <h3 style={{ color: "dodgerblue" }}>
                <strong>Truck</strong>:{selectedTruckDevice}
              </h3>
              {/* <h3 style={{ color: '#E91E63' }}><strong>Trailer</strong>: {selectedTrailerDevice}</h3> */}
              <div>
                Last
{" "}
                <Select
                  value={this.state.hours}
                  size="small"
                  style={{ width: 70 }}
                  onChange={this.handleLastHoursChange}
                >
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="12">12</Select.Option>
                  <Select.Option value="24">24</Select.Option>
                  <Select.Option value="48">48</Select.Option>
                </Select>
{" "}
                hours
              </div>
              <TruckMatchesDropdown />
            </Row>
            <TrailerHistoryChart
              truckData={truckLocationsHistory}
              trailerData={trailerLocationsHistory}
              onPosChange={this.onPosChange}
              trips={trips}
              onTripSelection={this.onTripSelection}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { positionHistory } = state;
  const { selectedTruck } = state.trucks;
  return {
    truckLocationsHistory: positionHistory.truck.locationsHistory
      ? positionHistory.truck.locationsHistory
      : null,
    trailerLocationsHistory: positionHistory.trailer.locationsHistory
      ? positionHistory.trailer.locationsHistory
      : null,
    selectedTruckDevice: state.matchings.selectedTruck,
    selectedTrailerDevice: state.matchings.selectedTrailer,
    trips: selectedTruck ? selectedTruck.trips : null,
    selectedTruckId: state.matchings.selectedTruckId,
    selectedTrailerId: state.matchings.selectedTrailerId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTruckLocationsHistory: (truckId, hours) =>
      dispatch(actions.positionHistory.getTruckPositionHistory(truckId, hours)),
    fetchTrailerLocationsHistory: (trailerDeviceId, hours) =>
      dispatch(
        actions.positionHistory.getTrailerPositionHistory(
          trailerDeviceId,
          hours
        )
      ),
    selectTrailerId: trailerId =>
      dispatch(actions.matchings.selectTrailer(trailerId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrailerHistory);
