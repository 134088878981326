import Amplify, { Auth } from "aws-amplify";

const {
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_APP_CLIENT_ID,
  REACT_APP_API_GATEWAY_URL
} = process.env;

export const configureAuth = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
      region: REACT_APP_COGNITO_REGION,
      userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
      mandatorySignIn: false
    }
  });
};

export const configureAPI = () => {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: "TruckTrailerMatching",
          endpoint: `${REACT_APP_API_GATEWAY_URL}`,
          region: "eu-west-1",
          custom_header: async () => {
            // jwtToken to restrict trailers access by fleet
            // (transics user have no currentSession thus we set jwtToken=undefined
            // so that they have access to all trailers)
            const jwtToken = await Auth.currentSession()
              .then(r => r.getIdToken().getJwtToken())
              .catch(_ => undefined);
            const jwtTokenHeader = jwtToken && {
              "X-amz-cognito-token": jwtToken
            };
            // transics token
            const transicsToken = window.localStorage.getItem("transics-token");
            const transicsTokenHeader = transicsToken && {
              "X-transics-token": transicsToken
            };
            return {
              ...jwtTokenHeader,
              ...transicsTokenHeader
            };
          }
        }
      ]
    }
  });
};
