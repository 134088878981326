import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../actions";

class SendToken extends Component {
  componentDidMount() {
    const {
      match: {
        params: { token = "", env }
      },
      authenticateUser
    } = this.props;
    if (token && token.length > 0) {
      return authenticateUser(token, env);
    }
    return null;
  }

  render() {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateUser: (token, env) =>
      dispatch(actions.auth.authUser(token, env))
  };
}

export default connect(undefined, mapDispatchToProps)(SendToken);
