import React, { Component } from "react";
import { Route } from "react-router";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import Layout from "./Layout";
import store, { history } from "./createStore";
import {
  configureAPI,
  configureAuth
} from "./configureEnvironment/configureAmplify";
import "./App.css";

configureAuth();
configureAPI();

class App extends Component {
  constructor() {
    super();
    this.state = {
      ready: false
    };
  }

  async componentDidUpdate(prevProps) {
    const { authState: prevAuthState } = prevProps;
    const { authState } = this.props;
    if (authState === "signedIn" && prevAuthState !== "signedIn") {
      this.setState({ ready: true });
    }
  }

  render() {
    const { ready } = this.state;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route
            render={routeProps => <Layout ready={ready} {...routeProps} />}
          />
        </ConnectedRouter>
      </Provider>
    );
  }
}

const AuthenticatedApp = () => <App />;

export default AuthenticatedApp;
