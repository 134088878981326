import { API } from "aws-amplify";
import actionTypes from "./actionTypes";

function getCustomerRequest() {
  return {
    type: actionTypes.CUSTOMER_GET_ALL_REQUEST
  };
}

function getCustomerFailure(error) {
  return {
    error,
    type: actionTypes.CUSTOMER_GET_ALL_FAILURE
  };
}

function getCustomerSuccess(customers) {
  return {
    customers,
    type: actionTypes.CUSTOMER_GET_ALL_SUCCESS
  };
}

function getCustomer() {
  return dispatch => {
    dispatch(getCustomerRequest());
    return API.get("TruckTrailerMatching", "customer/all")
      .then(res => dispatch(getCustomerSuccess(res)))
      .catch(err => dispatch(getCustomerFailure(err)));
  };
}

function selectCustomer(customer) {
  return {
    type: actionTypes.CUSTOMER_SELECT_CUSTOMER,
    customer
  };
}

export default { getCustomer, selectCustomer };
