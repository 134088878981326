import produce from "immer";
import actionTypes from "../actions/actionTypes";

const initialState = {
  processing: false,
  error: null
};

function skySyncReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.SKY_SYNC_REQUEST:
        draft.processing = true;
        break;
      case actionTypes.SKY_SYNC_FAILURE:
        draft.processing = false;
        draft.error = action.error;
        break;
      case actionTypes.SKY_SYNC_SUCCESS:
        draft.processing = false;
        break;
      default:
        return state;
    }
  });
}

export default skySyncReducer;
