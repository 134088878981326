// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import { Row, Col, Button, Popconfirm } from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import actions from "../../actions/index";

class Validation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validations: null
    };
  }

  componentDidMount() {
    const savedValidations = JSON.parse(
      localStorage.getItem("truckTrailerCouplingValidation")
    );
    const validations = savedValidations ? Object.values(savedValidations) : [];
    this.setState({
      validations: validations.map(v => ({
        ...v.couplingInfo,
        truckId: v.truckId,
        trailerId: v.trailerId,
        tripId: v.tripId,
        validated: v.validated ? "true" : "false"
      })),
      validationCount: validations.length
    });
  }

  delete = () => {
    const { resetValidation } = this.props;
    resetValidation();
    this.setState({
      validations: null,
      validationCount: 0
    });
  };

  render() {
    const { validations, validationCount } = this.state;

    const csvProps = {
      filename: `truck-trailer-coupling-validations_${moment().format(
        "YYYYMMDD-hhmm"
      )}`
    };
    return (
      <div style={{ backgroundColor: "white", textAlign: "center" }}>
        <Row type="flex">
          <Col
            span={24}
            style={{
              height: "calc(100vh - 64px)",
              overflowY: "scroll",
              padding: 16,
              paddingTop: "25vh"
            }}
          >
            {!validationCount && (
              <div>No validation of coupling performed yet.</div>
            )}
            {validationCount > 0 && (
              <div>
                <div>
                  {numeral(validationCount).format("0,0")}
{' '}
Validations performed
</div>
                <div style={{ marginTop: 16 }}>
                  <Button onClick={this.export} style={{ marginRight: 16 }}>
                    <CSVLink {...csvProps} data={validations}>
                      Export as CSV
                    </CSVLink>
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete all validations？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={this.delete}
                  >
                    <Button type="danger">Delete</Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    resetValidation: () => dispatch(actions.validation.resetValidation())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Validation);
