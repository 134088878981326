// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Icon, Button, Input, Badge, Rate } from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";

import classNames from "./TruckTrailerTable.module.css";
// import dateTimeFormatter from "../../helpers/dateTimeFormatter";
import type { MatchingType } from "../../mappings/matchings";
// import RenderBar from "../RenderBar/RenderBar";
import actions from "../../actions";
import HistoricalMatchingStepView from "../HistoricalMatchingStepView/HistoricalMatchingStepView";

/* function formatConfidence(value: number): number {
  return (
    <span className={classNames.column}>{Math.round(value * 100) / 100}</span>
  );
} */

type PropsType = {
  matchings: MatchingType[],
  selectTruckId: (truckId: number) => void,
  selectTrailerId: (truckId: number) => void
};

type StateType = {
  searchText: string
};

class TruckTrailerTable extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      searchText: "",
      expandedRowKeys: []
    };
  }

  handleSearch = (selectedKeys: [string], confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onExpand = (expanded, row) => {
    this.setState({ expandedRowKeys: expanded ? [row.truckId] : [] });
    if (expanded) {
      const { truckId, latestBestMatchTrailerId } = row;
      const { selectTruckId, selectTrailerId } = this.props;
      selectTruckId(truckId);
      selectTrailerId(latestBestMatchTrailerId);
    }
  };

  render() {
    const { trucks, selectedtruckId } = this.props;
    const { searchText } = this.state;
    // const maxDistance = Math.max(...trucks.map(m => m.latestBestMatchAverageDistance));
    const columns = [
      {
        title: "Truck",
        dataIndex: "truckNumplate",
        key: "truckNumplate",
        className: [classNames.dimensionColumn, classNames.column].join(" "),
        sorter: (a, b) => {
          if (a.truckNumplate < b.truckNumplate) {
            return -1;
          }
          if (a.truckNumplate > b.truckNumplate) {
            return 1;
          }
          return 0;
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder="Plate number"
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon
            type="search"
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        ),
        onFilter: (value, record) => {
          return record.truckNumplate
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: (text, record) => (
          <Badge dot={selectedtruckId === record.truckId}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          </Badge>
        )
      },
      {
        title: "Latest Match",
        dataIndex: "bestMatchTrailerNumPlate",
        key: "bestMatchTrailerNumPlate",
        className: [classNames.dimensionColumn, classNames.column].join(" ")
      },
      {
        title: "Duration",
        dataIndex: "latestTripDuration",
        key: "latestTripDuration",
        className: classNames.column,
        render: text => (
<div>
{text} m</div>
),
        sorter: (a, b) => a.latestTripDuration - b.latestTripDuration
      },
      {
        title: "Distance",
        dataIndex: "latestTripDistance",
        key: "latestTripDistance",
        className: classNames.column,
        render: text => (
<div>
{text} km</div>
),
        sorter: (a, b) => a.latestTripDistance - b.latestTripDistance
      },
      {
        title: "Score",
        dataIndex: "stars",
        key: "stars",
        render: stars => (
          <div>
            {stars != null && (
              <span>
                <Rate style={{ fontSize: 10 }} disabled defaultValue={stars} />
              </span>
            )}
            {stars == null && <span>–</span>}
          </div>
        ),
        className: [classNames.column].join(" "),
        sorter: (a, b) => {
          if (a.stars === null) {
            return -1;
          }
          if (b.stars === null) {
            return 1;
          }
          if (a.stars === b.stars) {
            return 0;
          }
          return a.stars < b.stars ? -1 : 1;
        },
        defaultSortOrder: "descend"
      },
      {
        title: "Trip Start",
        dataIndex: "latestTripStartTime",
        key: "latestTripStartTime",
        render: text => moment(text).format("MMM D - HH:mm"),
        className: classNames.column,
        sorter: (a, b) => {
          if (moment(a.latestTripStartTime).isBefore(b.latestTripStartTime)) {
            return -1;
          }
          return 1;
        }
      },
      {
        title: "Freq",
        dataIndex: "highFreqTrailer",
        key: "highFreqTrailer",
        render: (text, { highFreqTrailer }) => (
          <div>
            {highFreqTrailer && (
              <span>
                <Icon
                  style={{ fontSize: 12, color: "seagreen" }}
                  type="check"
                />{" "}
                High
              </span>
            )}
            {!highFreqTrailer && (
              <span>
                <Icon style={{ fontSize: 12, color: "tomato" }} type="close" />{" "}
                Low
              </span>
            )}
          </div>
        ),
        className: [classNames.column].join(" "),
        sorter: (a, b) => {
          if (a.highFreqTrailer === null) {
            return -1;
          }
          if (b.highFreqTrailer === null) {
            return 1;
          }
          if (a.highFreqTrailer === b.highFreqTrailer) {
            return 0;
          }
          return a.highFreqTrailer < b.highFreqTrailer ? -1 : 1;
        }
      }
    ];
    return (
      <div className={classNames.container}>
        <Table
          size="small"
          dataSource={trucks}
          columns={columns}
          pagination={{ pageSize: 20 }}
          rowKey="truckId"
          /* onRow={truck => {
            return {
              onClick: () => {
                const { truckId, latestBestMatchTrailerId } = truck;
                selectTruckId(truckId);
                selectTrailerId(latestBestMatchTrailerId);
              }
            };
          }} */
          expandedRowRender={truck => (
            <HistoricalMatchingStepView truck={truck} />
          )}
          expandRowByClick
          expandedRowKeys={this.state.expandedRowKeys}
          onExpand={this.onExpand}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedtruckId: state.matchings.selectedTruckId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectTruckId: truckId => dispatch(actions.matchings.selectTruck(truckId)),
    selectTrailerId: trailerId =>
      dispatch(actions.matchings.selectTrailer(trailerId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckTrailerTable);
