import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CouplingSummary from './CouplingSummaryChart';

function getChartState(props) {
  return {
    couplings: props.couplings,
    apiCalls: props.apiCalls,
    start: props.start,
    end: props.end,
    startLabel: props.startLabel,
    endLabel: props.endLabel,
    trailerColorMap: props.trailerColorMap,
    type: props.type // 'time' or 'odo'
  };
}

class CouplingSummaryChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const currentState = getChartState(nextProps);
    if (nextProps.couplings && chart) {
      chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state
    if (chart) {
      chart.destroy()
    }

    const { width } = this.props
    const elWidth = width || 150
    const elHeight = 40

    const margin = {
      top: 0,
      right: 8,
      bottom: 0,
      left: 4,
    };

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new CouplingSummary(el, props),
    }, function callback() {
      this.state.chart.create();
      this.state.chart.update(getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div
        ref='chart'
      />
    );
  }
}

export default CouplingSummaryChartComponent
