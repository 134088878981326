import { message } from "antd";
import actionTypes from "../actions/actionTypes";

const messageMiddleware = () => next => action => {
  next(action);
  if (action.type === actionTypes.MESSAGE_DISPLAY_ONE) {
    const { messageType, content } = action;
    return message[messageType](content);
  }
  if (action.globalMessage) {
    const { type, content: messageContent } = action.globalMessage;
    return message[type](messageContent);
  }
};

export default messageMiddleware;
