// @flow
import React from "react";
import { Progress } from "antd";

type RenderBarProps = {
  value: number,
  maxValue: number,
  formatValue: number => number,
  small?: boolean
};

function RenderBar({ value, maxValue, formatValue, small }: RenderBarProps) {
  return (
    <Progress
      status="normal"
      strokeWidth={small ? 5 : 10}
      format={() => formatValue(value)}
      percent={(100 * value) / maxValue}
    />
  );
}

export default RenderBar;
