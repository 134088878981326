import React, { Component } from "react";
import { Avatar, Icon, Popover, Divider } from "antd";
import { connect } from "react-redux";

import actions from "../../actions";
import classNames from "./UserMenu.module.css";

const UserDropdown = ({ logOut, userName }) => (
  <div>
    <p style={{ color: "gray" }}>{userName}</p>
    <Divider />
    <div
      role="button"
      tabIndex={0}
      className={classNames.userMenuMenuItem}
      onClick={logOut}
      onKeyDown={logOut}
    >
      <Icon type="logout" style={{ marginRight: 8 }} />
      Log out
    </div>
  </div>
);

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.props.getUser();
  }

  toggleMenuOpen = () => {
    const { open } = this.state;
    return this.setState({ open: !open });
  };

  onLogout = () => {
    const { logout } = this.props;
    this.setState({ open: false });
    return logout();
  };

  render() {
    const { open } = this.state;
    const { userName } = this.props;
    const user = userName ? userName.split("@")[0] : "";
    if (user) {
      return (
        <Popover
          className={classNames.userMenuPopover}
          trigger="click"
          placement="bottomRight"
          content={<UserDropdown logOut={this.onLogout} userName={userName} />}
          visible={open}
          onVisibleChange={this.toggleMenuOpen}
        >
          <Avatar icon="user" size="large" />
        </Popover>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    processing: user.processing,
    error: user.error,
    userName: user.user && (user.user.email || user.user.attributes.email)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(actions.user.signOut()),
    getUser: () => dispatch(actions.auth.checkUserLoggedIn())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
