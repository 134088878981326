/* eslint-disable eqeqeq */
import produce from "immer";
// import moment from "moment";

import actionTypes from "../actions/actionTypes";
import matchingsMapping from "../mappings/matchings";

// const { REACT_APP_LAST_HOURS_VIEW } = process.env;

const initialState = {
  matchings: {
    items: null,
    loading: false,
    error: null
  },
  selectedTruckId: null,
  selectedTrailerId: null,
  selectedTruck: null,
  selectedTrailer: null,
  selectConfidence: null
};

export default function matchingsReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_REQUEST:
        draft.matchings.loading = true;
        break;
      case actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_SUCCESS:
        const items = action.matchings.map(matchingsMapping);
        draft.matchings.items = draft.matchings.items
          ? [].concat(items)
          : items;
        draft.matchings.loading = false;
        break;
      case actionTypes.TRUCK_TRAILER_MATCHINGS_FETCH_FAILURE:
        draft.matchings.error = action.error;
        draft.matchings.loading = false;
        break;
      case actionTypes.TRUCK_TRAILER_MATCHING_SELECT_TRUCK:
        draft.selectedTruckId = action.truckId;
        if (state.matchings) {
          // eslint-disable-next-line eqeqeq
          const match = state.matchings.items.find(
            m => m.truckId == action.truckId // && m.numPlate
          );
          draft.selectedTruck = match ? match.truckNumplate : null;
        }
        break;
      case actionTypes.TRUCK_TRAILER_MATCHING_SELECT_TRAILER:
        draft.selectedTrailerId = action.trailerId;
        if (state.matchings) {
          // eslint-disable-next-line eqeqeq
          const match = state.matchings.items.find(
            m => m.trailerId == action.trailerId // && m.matched
          );
          draft.selectedTrailer = match ? match.trailerNumPlate : null;
        }
        break;
      default:
        return draft;
    }
  });
}
