import { API } from "aws-amplify";
import actionTypes from "./actionTypes";

function skySyncRequest() {
  return {
    type: actionTypes.SKY_SYNC_REQUEST
  };
}

function skySyncSuccess() {
  return {
    type: actionTypes.SKY_SYNC_SUCCESS,
    globalMessage: {
      type: "success",
      content: "Sky sync request successfully transmitted"
    }
  };
}

function skySyncFailure(error) {
  return {
    type: actionTypes.SKY_SYNC_FAILURE,
    error,
    globalMessage: {
      type: "error",
      content: "Could not transmit Sky sync request"
    }
  };
}

function skySync() {
  return (dispatch, getState) => {
    dispatch(skySyncRequest());
    const state = getState();
    const { selectedCustomer } = state.customer;
    const url = `syncCouplings/${selectedCustomer.fleetCode}`;
    return API.get("TruckTrailerMatching", url)
      .then(() => dispatch(skySyncSuccess()))
      .catch(() => dispatch(skySyncFailure()));
  };
}

export default {
  skySync
};
