import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import TrailerHistoryChart from './TrailerHistoryChart';

class TrailerHistoryChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    if (chart) {
      this.createChart()
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state
    if (chart) {
      chart.destroy()
    }

    const margin = {
      top: 5,
      right: 10,
      bottom: 45,
      left: 35,
    };
    const { height, width } = this.props
    const elWidth = width || el.offsetWidth
    const elHeight = height || 150

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new TrailerHistoryChart(el, props),
    }, function callback() {
      this.state.chart.create();
      this.state.chart.update(this.props);
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div
        ref='chart'
      />
    );
  }
}

export default TrailerHistoryChartComponent
