import { API } from "aws-amplify";
import actionTypes from "./actionTypes";
import cleanUrl from "../helpers/cleanUrl";

function getTruckHistoricCouplingRequest() {
  return {
    type: actionTypes.GET_TRUCK_COUPLINGS_REQUEST
  };
}

function getTruckHistoricCouplingFailure(error) {
  return {
    type: actionTypes.GET_TRUCK_COUPLINGS_FAILURE,
    error
  };
}

function getTruckHistoricCouplingSuccess(truckCouplings, truckApiCalls) {
  return {
    type: actionTypes.GET_TRUCK_COUPLINGS_SUCCESS,
    truckCouplings,
    truckApiCalls
  };
}

async function loopApi(urlPath) {
  let lastKey;
  let results = [];
  do {
    const url = `${urlPath}${
      lastKey
        ? `?firstkey=${JSON.stringify(lastKey)}&timeFilter=200`
        : "?timeFilter=200"
    }`;
    const cleanedUrl = cleanUrl(url);
    const callResults = await API.get("TruckTrailerMatching", cleanedUrl);
    const { trucks, pagination } = callResults;
    lastKey = pagination.lastKey;
    results = [].concat(results, trucks);
  } while (lastKey);
  return results;
}

async function loopApi2(urlPath) {
  let lastKey;
  let results = [];
  do {
    const url = `${urlPath}${
      lastKey
        ? `?firstkey=${JSON.stringify(lastKey)}&timeFilter=24`
        : "?timeFilter=24"
    }`;
    const cleanedUrl = cleanUrl(url);
    const callResults = await API.get("TruckTrailerMatching", cleanedUrl);
    const { trucks, pagination } = callResults;
    lastKey = pagination.lastKey;
    results = [].concat(results, trucks);
  } while (lastKey);
  return results;
}

function getTruckHistoricCoupling() {
  return (dispatch, getState) => {
    const state = getState();
    const { selectedCustomer } = state.customer;
    dispatch(getTruckHistoricCouplingRequest());
    const urlPath2 = `truck/${selectedCustomer.fleetCode}`;
    const urlPath = `couplings/${selectedCustomer.fleetCode}/all`;
    return Promise.all([loopApi(urlPath), loopApi2(`${urlPath2}/api-calls`)])
      .then(([couplings, apiCalls]) => {
        return dispatch(getTruckHistoricCouplingSuccess(couplings, apiCalls));
      })
      .catch(err => dispatch(getTruckHistoricCouplingFailure(err)));
  };
}

export default {
  getTruckHistoricCoupling
};
