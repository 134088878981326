// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Menu } from "antd";
import _ from "lodash";
import moment from "moment";

import actions from "../../actions";
import RenderBar from "../RenderBar/RenderBar";
import type { MatchingType } from "../../mappings/matchings";

type PropsType = {
  couplings: ?(MatchingType[]),
  selectTruckTrailerMatch: (trailerId: number) => void
};

class TruckMatchesDropdown extends Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onTrailerSelection = item => {
    const { selectTruckTrailerMatch } = this.props;
    selectTruckTrailerMatch(item.key);
  };

  getMenu = couplings => {
    return (
      <Menu onClick={this.onTrailerSelection}>
        {couplings.map(coupling => (
          <Menu.Item key={coupling.trailerId}>
            <Row type="flex">
              {coupling.matched} ({coupling.trailerId})
              <div style={{ marginLeft: 8, width: 80 }}>
                <RenderBar
                  value={coupling.confidence}
                  maxValue={1}
                  small
                  formatValue={t => (
                    <span style={{ fontSize: 10 }}>
                      {Math.round(100 * t) / 100}
                    </span>
                  )}
                />
              </div>
            </Row>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  render() {
    const { couplings, selectedTrailerDevice } = this.props;
    if (!couplings) {
      return null;
    }
    //const menu = this.getMenu(couplings);
    return (
      <div>
        {/*<Dropdown overlay={menu} trigger={['click']}>
            <h3 style={{ color: '#E91E63' }}><strong>Trailer</strong>: {selectedTrailerDevice} <Icon type="down" /></h3>
        </Dropdown>*/}
        <h3 style={{ color: '#E91E63' }}><strong>Trailer</strong>: {selectedTrailerDevice}</h3>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedTruckId } = state.matchings;
  if (!selectedTruckId) {
    return {
      couplings: null
    };
  }
  const matchings = state.matchings.matchings.items;
  if (!matchings) {
    return {
      couplings: null
    };
  }

  const mostRecentMatchingPerTrailer = Object.values(_.groupBy(
      matchings.filter(m => m.truckId === selectedTruckId && m.trailerId), 
      m => m.trailerId
    ))
    .map(truckTrailerMatches => _.maxBy(truckTrailerMatches
        .filter(m => m.trailerId && m.matched && m.confidence && moment(m.matchStartTime).isAfter(moment().subtract(3, 'hours')))
      , m => m.confidence))
    .filter(e => e)
    .sort((a, b) => b.confidence - a.confidence)
    .filter((_, index) => index < 5)
  return {
    couplings: mostRecentMatchingPerTrailer,
    selectedTrailerDevice: state.matchings.selectedTrailer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectTruckTrailerMatch: trailerId =>
      dispatch(actions.matchings.selectTrailer(trailerId))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TruckMatchesDropdown);
