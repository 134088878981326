// @flow
import actionTypes from "./actionTypes";

export type MessageType = "info" | "error" | "success" | "warning";

type MessageActionType = {
  type: string,
  messageType: MessageType,
  content: string
};

function displayMessage(
  messageType: MessageType,
  content: string
): MessageActionType {
  return {
    type: actionTypes.MESSAGE_DISPLAY_ONE,
    messageType,
    content
  };
}

export default {
  displayMessage
};
