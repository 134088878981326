// @flow
import * as d3 from "d3";

const blue = [58, 144, 224];
const pink = [233, 30, 99];

type RgbColorType = [Number, Number, Number];

function rgbFormatter(rgbColor: RgbColorType): string {
  return `rgb(${rgbColor.join(",")})`;
}

const colors = {
  trailer: pink,
  truck: blue
};

export const cssColors = Object.keys(colors).reduce((agg, elem) => {
  agg[elem] = rgbFormatter(colors[elem]);
  return agg;
}, {});

export const createTrailerColorMap = trailerIdList => {
  const trailerColors = d3.schemeCategory10.filter(e => e !== "#7f7f7f") // grey is confusing
  const colorMap = trailerIdList
    .filter(id => !!id)
    .reduce((res, id, i) => {
        res[id] = trailerColors[i % trailerColors.length];
        return res;
      }, {});
  colorMap[0] = '#adadad'
  return colorMap
}

export default colors;
