// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Steps, Divider, Badge, Rate, Row, Col, Button, Menu } from "antd";
import moment from "moment";
import numeral from "numeral";

import actions from "../../actions";

class HistoricalMatchingStepView extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {};
  }

  selectTrailer = trailerId => {
    const { selectTrailerId, selectTruckId, truck } = this.props;
    selectTruckId(truck.truckId);
    selectTrailerId(trailerId);
  }

  onValidation = (truckId, trailerId, trip, validated) => {
    const { validateCoupling } = this.props;
    const couplingInfo = trip.bestMatches[0];
    validateCoupling(truckId, trailerId, trip.tripId, validated, couplingInfo);
  };

  onAlternativeSelection = (truckId, trailerId, trip) => {
    const { validateCoupling } = this.props;
    const alternativeCouplingInfo = trip.bestMatches
      .find(m => m.trailerId === trailerId);
    validateCoupling(truckId, trailerId, trip.tripId, true, alternativeCouplingInfo);
  };

  getAlternativeMenu = (truckId, trip) => {
    return (
      <Menu onClick={trailerId => this.onAlternativeSelection(truckId, trailerId, trip)}>
        {trip.bestMatches.filter((m, i) => i > 0 && i <= 6 && m.trailerId).map(alternative => (
          <Menu.Item key={alternative.trailerId}>
            <span>{alternative.trailerNumPlate}<small>({alternative.pathMedianDistance} Km)</small></span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  render() {
    const { truck, selectedTrailerId } = this.props;
    const { trips, truckId } = truck;
    return (
      <div>
        <Steps direction="vertical" size="small" current={1}>
          {trips.map((trip, i) => {
            const alternativeMatches = trip.bestMatches
              .filter((m, i) => i > 0 && i <= 6 && m.trailerId);
            //const menu = this.getAlternativeMenu(truckId, trip);
            return (
              <Steps.Step
                key={i}
                title={
                  <div>
                    Trip {i + 1} <Divider type="vertical" /> Duration{" "}
                    {moment
                      .utc(
                        moment
                          .duration(+trip.tripDuration, "minutes")
                          .asMilliseconds()
                      )
                      .format("HH:mm")}{" "}
                    <Divider type="vertical" /> {trip.tripDistance} Km
                  </div>
                }
                status={trip.bestMatchTrailerNumPlate ? "finish" : "wait"}
                description={
                  <Row type="flex">
                    <Col span={16}>
                      <div>From {moment(trip.tripStartTime).format("MMM D, HH:mm")} to {moment(trip.tripEndTime).format("MMM D, HH:mm")}</div>
                      {trip.bestMatchTrailerNumPlate && 
                        <div>
                          <div>Best matching trailer: </div>
                          <div style={{ marginLeft: 8 }}>
                            <div><span style={{ fontWeight: 'bold', cursor: "pointer" }} onClick={() => this.selectTrailer(trip.bestMatchTrailerId)}>{<Badge dot={trip.bestMatchTrailerId === selectedTrailerId}>{trip.bestMatchTrailerNumPlate}</Badge>}</span> (ID: {trip.bestMatchTrailerId}) <Rate style={{ fontSize: 10 }} disabled defaultValue={trip.stars} /></div>
                            <div>Average path distance of {trip.bestMatchAverageDistance} Km </div>
                          </div>
                        </div>
                      }
                      {/*<pre>{JSON.stringify(trip, null, 2)}</pre>*/}
                      {trip.bestMatchTrailerNumPlate && 
                        <div>
                          Alternative matches: 
                            <div style={{ marginLeft: 8 }}>
                              {alternativeMatches.length > 0 ? alternativeMatches.map( match => 
                                <span style={{ cursor: "pointer" }} key={match.trailerId} onClick={() => this.selectTrailer(match.trailerId)}><Badge dot={match.trailerId === selectedTrailerId}>{match.trailerNumPlate}</Badge> <small>({match.pathMedianDistance} Km)</small>{' '}</span>) : 'None'}
                            </div>
                        </div>
                      }
                      {!trip.bestMatchTrailerNumPlate && <div style={{ fontWeight: 'bold' }}>No matching trailer found!</div>}
                      {(!trip.bestMatchTrailerNumPlate && trip.tempAlternativeToBestMatches.length > 0) && 
                        <div>
                          Debugging -> Other matches without median distance: 
                            <div style={{ marginLeft: 8 }}>
                              {trip.tempAlternativeToBestMatches.map( match => 
                                <span style={{ cursor: "pointer" }} key={match.trailerId} onClick={() => this.selectTrailer(match.trailerId)}><Badge dot={match.trailerId === selectedTrailerId}>{match.trailerNumPlate ? match.trailerNumPlate : 'No NumPlate'}</Badge> <small>({numeral(match.confidence).format('0.00')} confidence)</small>{' '}</span>)}
                            </div>
                        </div>
                      }
                    </Col>
                    <Col span={8}>
                      Validation: 
                      <Row>
                        <Button onClick={() => this.onValidation(truckId, trip.bestMatchTrailerId, trip, true)} type={trip.validated != null && trip.validated ? 'primary' : ''} shape="circle" icon="check" />
                        <Button onClick={() => this.onValidation(truckId, trip.bestMatchTrailerId, trip, false)} style={{ marginLeft: 8 , color: (trip.validated != null && !trip.validated ? '#fff' : ''), backgroundColor: (trip.validated != null && !trip.validated ? 'tomato' : '') }} shape="circle" icon="close" />
                      </Row>
                      {/*(trip.validated !== null) && 
                        <div>
                          Should rather be 
                          <div>
                            <Dropdown overlay={menu} trigger={['click']}>
                              <span style={{color: 'dodgerblue'}}>
                                {' '}
                                { (trip.validation && trip.validation.alternative) && trip.validation.alternative}
                                { !(trip.validation && trip.validation.alternative) && 'choose'}
                                {' '}
                                <Icon type="down" />
                              </span>
                            </Dropdown>
                          </div>
                        </div>
                      */}
                    </Col>
                    {/* <pre>{JSON.stringify(trip,null,2)}</pre> */}
                  </Row>
                } 
              />
            )})
          }
        </Steps>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedTruckId: state.matchings.selectedTruckId,
    selectedTrailerId: state.matchings.selectedTrailerId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectTruckId: truckId => dispatch(actions.matchings.selectTruck(truckId)),
    selectTrailerId: trailerId =>
      dispatch(actions.matchings.selectTrailer(trailerId)),
    validateCoupling: (truckId, trailerId, tripId, validated, couplingInfo) =>
      dispatch(
        actions.validation.validateCoupling(
          truckId,
          trailerId,
          tripId,
          validated,
          couplingInfo
        )
      )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalMatchingStepView);
