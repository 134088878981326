import React from "react";

import unsplash from "../../assets/unsplash.jpg";

const styles = {
  container: {
    backgroundImage: `url(${unsplash})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  homeContent: {
    textAlign: "center",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  clientLogo: {
    // height: LineasLogoHeight,
    marginBottom: 24
  },
  appName: {
    fontSize: 36,
    textShadow: "1px 1px white"
  }
};

function Home() {
  return <div style={styles.container} />;
}

export default Home;
