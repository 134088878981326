import actionTypes from "./actionTypes";

function validateCouplingRequest(
  truckId,
  trailerId,
  tripId,
  validated,
  couplingInfo
) {
  let validation = JSON.parse(
    localStorage.getItem("truckTrailerCouplingValidation")
  );
  validation = validation || {};
  validation[`${truckId}_${trailerId}_${tripId}`] = {
    truckId,
    trailerId,
    tripId,
    validated,
    couplingInfo
  };
  localStorage.setItem(
    "truckTrailerCouplingValidation",
    JSON.stringify(validation)
  );
  return {
    type: actionTypes.VALIDATE_COUPLING,
    truckId,
    trailerId,
    tripId,
    validated,
    couplingInfo
  };
}

function validateCoupling(truckId, trailerId, tripId, validated, couplingInfo) {
  return dispatch => {
    dispatch(
      validateCouplingRequest(
        truckId,
        trailerId,
        tripId,
        validated,
        couplingInfo
      )
    );
  };
}

function resetAllValidations() {
  localStorage.setItem("truckTrailerCouplingValidation", null);
  return {
    type: actionTypes.RESET_VALIDATION
  };
}

function resetValidation() {
  return dispatch => {
    dispatch(resetAllValidations());
  };
}

export default {
  validateCoupling,
  resetValidation
};
