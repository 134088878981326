// @flow

export type MatchingType = {
  matchedTripId: string,
  numPlate: string,
  pathMedianDistance: string,
  pathMeanDistance: string,
  highFreqTrailer: string,
  stars: string,
  tripDistance: string,
  tripDuration: string,
  trailerId: string,
  trailerNumPlate: string,
  tripStartTime: string,
  tripEndTime: string,
  truckId: string,
  tripId: string,
  truckNumplate: string
};

function matchingMapping(matching: any): MatchingType {
  return {
    matchedTripId: matching.matchedtripId,
    //numPlate: matching.truckPlate,
    pathMedianDistance: +matching.medianDistance,
    pathMeanDistance: +matching.meanDistance,
    highFreqTrailer: matching.trailer.highFreqTrailer,
    stars: matching.stars ? +matching.stars : null,
    tripDistance: matching.trip.lastOdo && matching.trip.startOdo ? matching.trip.lastOdo - matching.trip.startOdo : null,
    tripDuration: matching.trip.duration,
    trailerId: matching.trailer.trailerID,
    trailerNumPlate: matching.trailer.trailerPlate,
    tripStartTime: matching.trip.startTime ? new Date(matching.trip.startTime*1000) : null,
    tripEndTime: matching.trip.lastTime ? new Date(matching.trip.lastTime*1000) : null,
    truckId: matching.truckId,
    tripId: matching.trip.tripId,
    truckNumplate: matching.truckPlate
  };
}

export default matchingMapping;
