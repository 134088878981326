import produce from "immer";
import actionTypes from "../actions/actionTypes";

const initialState = {
  processing: false,
  error: null,
  session: null,
  user: null,
  token: null
};

export default function userReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.USER_RETRIEVE_SESSION_REQUEST:
        draft.processing = true;
        break;
      case actionTypes.USER_RETRIEVE_SESSION_FAILURE:
        draft.processing = false;
        draft.error = action.error;
        break;
      case actionTypes.USER_LOGIN_SUCCESS:
        draft.user = action.user;
        draft.processing = false;
        break;
      case actionTypes.USER_LOGOUT_SUCCESS:
        draft.user = initialState.user;
        draft.token = initialState.token;
        break;
      case actionTypes.USER_SIGN_OUT:
        draft.user = initialState.user;
        draft.token = initialState.token;
        break;
      case actionTypes.USER_RETRIEVE_SESSION_SUCCESS:
        draft.processing = false;
        draft.user = action.user;
        draft.session = action.session;
        draft.token = action.token;
        break;
      default:
        return state;
    }
  });
}
