import React from 'react'
import PropTypes from 'prop-types'
import {
  Table, Button, Input, Icon, Menu, Dropdown,
} from 'antd'
import Highlighter from 'react-highlight-words'
import { CSVLink } from 'react-csv'
import moment from 'moment'

import './JetpackTable.css'

class JetpackTable extends React.Component {
  constructor() {
    super();
    this.state = {
      searchInputRefs: {},
      filteredInfo: {},
    };
  }

  getMenuFromActions = (actions) => {
    const { data, columns } = this.props;
    const csvProps = {
      headers: columns.map(el => ({ label: el.title, key: el.dataIndex })),
      filename: `table-data-exported-${moment().format('YYYYMMDD-hhmm')}`,
    }
    /* eslint-disable react/no-array-index-key */
    const menuItems = actions.map((elem, i) => {
      switch (elem) {
        case '-': return <Menu.Divider key={i} />
        case 'clearAll': return (
          <Menu.Item key={i} onClick={this.clearAllFilters}>
            Clear all filters
          </Menu.Item>
        )
        case 'downloadAll': return (
          <Menu.Item key={i}>
            <CSVLink {...csvProps} data={data}>
              Export as CSV
            </CSVLink>
          </Menu.Item>
        )
        case 'downloadSelection': return (
          <Menu.Item key={i}>
            <CSVLink {...csvProps} data={this.getFilteredData()}>
              Export Selection as CSV
            </CSVLink>
          </Menu.Item>
        )
        default: return <Menu.Item key={i}>{elem}</Menu.Item>
      }
    })
    /* eslint-enable react/no-array-index-key */

    return (
      <Menu>
        { menuItems }
      </Menu>
    );
  }

  getFilteredData = () => {
    const { filteredInfo } = this.state;
    const { data, columns } = this.props;

    if (!filteredInfo) return data;

    // an array of function to filter the data
    const filterFunc = columns.map(({ key, dataIndex, filterType }) => {
      key = key || dataIndex; // eslint-disable-line no-param-reassign
      if (!filterType) return null;
      if (!filteredInfo[key] || filteredInfo[key].length === 0) return null;
      if (filterType === 'categorial') return row => filteredInfo[key].includes(row[dataIndex] || 'unknown'); // true if value is in the list
      if (filterType === 'search') return row => (row[dataIndex] || '').toString().toLowerCase().includes(filteredInfo[key][0].toLowerCase()) // true if value contains the string
      return null;
    }).filter(x => !!x)
    const combinedFilterFunc = row => filterFunc.reduce((acc, func) => acc && func(row), true);

    // filter data with the combination of all functions
    return data.filter(combinedFilterFunc);
  }

  getSorter = (sorterType, key) => {
    // define some sorter functions
    const simpleSort = (a, b) => {
      if (!a[key]) return -1; // undefined, null, '', NaN and 0 are always smaller
      if (!b[key]) return 1;
      return a[key] > b[key] ? 1 : -1;
    };
    const numberSort = (a, b) => {
      if (!a[key] && a[key] !== 0) return -1; // 0 must pass the test
      if (!b[key] && b[key] !== 0) return 1;
      return a[key] > b[key] ? 1 : -1;
    };
    const dateSort = (a, b) => {
      if (!a[key]) return -1;
      if (!b[key]) return 1;
      return new Date(a[key]) > new Date(b[key]) ? 1 : -1;
    };

    // supports 'number', 'text', 'datestr', 'dateobj', 'date' ou 'auto'
    switch (sorterType) {
      case 'text':
      case 'dateobj': return simpleSort;
      case 'number': return numberSort;
      case 'datestr': return dateSort;
      // next we choose the sorter automatically based only on the first data row
      case 'date': {
        const { data } = this.props;
        if (!data || data.length === 0 || !data[0][key]) return null;
        const type = (typeof data[0][key] === 'string') ? 'datestr' : 'dateobj';
        return this.getSorter(type, key);
      }
      case 'auto': {
        const { data } = this.props;
        if (!data || data.length === 0) return null;
        const val = data[0][key];
        let type;
        switch (typeof val) {
          case 'number': type = 'number'; break;
          case 'string': type = (+(new Date(val))) ? 'datestr' : 'text'; break;
          case 'object': type = (typeof val.getYear === 'function') ? 'dateobj' : ''; break;
          default: type = ''; break;
        }
        if (!type) return null;
        return this.getSorter(type, key);
      }
      default: return null;
    }
  }

  getSearchDropdown = (key) => {
    const { searchInputRefs } = this.state;
    return (
      ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div className="jetpack-table-custom-filter-dropdown">
          <Input
            ref={(elem) => { searchInputRefs[key] = elem }}
            placeholder="Search value"
            value={selectedKeys && selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
          />
          <Button type="primary" onClick={confirm}>Search</Button>
          <Button onClick={clearFilters}>Reset</Button>
        </div>
      ))
  }

  getSearchRenderFunc = (key) => {
    const { filteredInfo } = this.state;
    return (text => (
      filteredInfo && filteredInfo[key] && filteredInfo[key][0]
        ? (
          <Highlighter
            highlightClassName="jetpack-table-highlight"
            searchWords={filteredInfo[key]}
            autoEscape
            textToHighlight={(text || '').toString()}
          />
        ) : (text || '').toString()
    ))
  }

  clearAllFilters = () => {
    this.setState({ filteredInfo: {} });
  }


  getColumns = () => {
    const { filteredInfo, searchInputRefs } = this.state;
    const { columns, data } = this.props;

    // foreach column
    return columns.map((col) => {
      let colSpec = col;
      const key = col.key || col.dataIndex; // can ignore key if dataIndex is unique

      // add filter according to filterType
      if (col.filterType === 'search') {
        colSpec = {
          ...colSpec,
          filteredValue: filteredInfo[key] || null,
          filterDropdown: this.getSearchDropdown(key),
          onFilterDropdownVisibleChange: (visible) => { if (visible) setTimeout(() => { searchInputRefs[key].focus() }) }, // eslint-disable-line max-len
          render: col.render || this.getSearchRenderFunc(key),
          filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
          onFilter: (value, record) => (record[col.dataIndex] || '').toString().toLowerCase().includes(value.toLowerCase()), // eslint-disable-line max-len
        }
      } else if (col.filterType === 'categorial') {
        const possibleValues = [...(new Set(data.map(d => (d[col.dataIndex] || 'unknown').toString())))];
        colSpec = {
          ...colSpec,
          filteredValue: filteredInfo[key] || colSpec.defaultFilteredValue || null,
          filters: possibleValues.filter(d => d).map(d => ({ text: d, value: d })),
          onFilter: (value, record) => ((record[col.dataIndex] || 'unknown').toString()).includes(value.toString()),
        }
      }

      // add sorter if sorterType is defined (priority to defined sorter)
      if (col.sorterType) {
        colSpec = {
          ...colSpec,
          sorter: col.sorter || this.getSorter(col.sorterType, col.dataIndex),
        }
      }

      return colSpec;
    })
  }

  // triggers a rerendering when filters is changed
  handleChange = (pagination, filters) => {
    if (filters) {
      this.setState({
        filteredInfo: filters,
      });
    }
  }

  render() {
    const { data, actions } = this.props;
    const processedColumns = this.getColumns();

    // adding a last column for the menu button
    if (actions) {
      const menu = this.getMenuFromActions(actions)
      processedColumns.push({
        title: (
          <Dropdown overlay={menu} placement="bottomRight">
            <Icon type="ellipsis" />
          </Dropdown>
        ),
        align: 'right',
        onHeaderCell: () => ({ width: '48px' }),
      })
    }

    return (
      <Table
        {...this.props}
        columns={processedColumns}
        dataSource={data}
        onChange={this.handleChange}
      />
    );
  }
}

JetpackTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.string || PropTypes.element),
}

JetpackTable.defaultProps = {
  columns: [],
  data: [],
  actions: null,
}

export default JetpackTable