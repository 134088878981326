import produce from "immer";

import actionTypes from "../actions/actionTypes";
import constants from "../helpers/constants";

const {
  LOCAL_STORAGE_KEYS: { SELECTED_CUSTOMER_LOCAL_STORAGE_KEY }
} = constants;

const initialState = {
  customers: { items: null, processing: false, error: null },
  selectedCustomer:
    JSON.parse(localStorage.getItem(SELECTED_CUSTOMER_LOCAL_STORAGE_KEY)) ||
    null
};

export default function customerReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.CUSTOMER_GET_ALL_REQUEST:
        draft.customers.loading = true;
        break;
      case actionTypes.CUSTOMER_GET_ALL_SUCCESS:
        draft.customers.items = action.customers;
        draft.customers.processing = false;
        break;
      case actionTypes.CUSTOMER_GET_ALL_FAILURE:
        draft.customers.items = null;
        draft.customers.error = action.error;
        draft.customers.processing = false;
        break;
      case actionTypes.CUSTOMER_SELECT_CUSTOMER:
        draft.selectedCustomer = action.customer;
        localStorage.setItem(
          SELECTED_CUSTOMER_LOCAL_STORAGE_KEY,
          JSON.stringify(action.customer)
        );
        break;
      default:
        return draft;
    }
  });
}
