import * as d3 from 'd3';
import Chart from '../Chart';
//import _ from 'lodash';

import './TrailerHistoryChart.css'

export default class TrailerHistoryChart extends Chart {
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.trips = this.main.append('g')
      .attr('class', 'trips')

    /*this.couplings = this.main.append('g')
      .attr('class', 'couplings')*/

    this.points = this.main.append('g')
      .attr('class', 'points')

    this.pin = this.svg.append('g')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`)
      .attr('class', 'pin');

    this.areas = this.main.append('g')
      .attr('class', 'areas')

    this.xAxis = this.main.append('g')
      .attr('class', 'xAxis trailerHistoryAxis')
      .attr('transform', `translate(0,${this.props.height})`)

    this.yLeftAxis = this.main.append('g')
      .attr('class', 'yAxis trailerHistoryAxis trailerHistoryYAxisLeft')
      .append('text')
      .attr('class', 'yLeftAxisLabel')
      .attr('fill', 'dodgerblue')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .text('Speed');

    this.legend = this.main.append('g')
      .attr('class', 'ledgend')
      .append('text')
      .attr('class', 'yLeftAxisLabel')
      .attr('x', -35)
      .attr('y', this.props.height + 35)
      .attr('text-anchor', 'start')
      .text('Trips');

    this.drawPin();
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state) {
      this.onPosChange = state.onPosChange;
      this.drawChart(state)
    }
  }

  drawChart(state) {
    this.trailerData = (state.trailerData || [])
      .map(d => ({ ...d, time: new Date(d.timeInMs * 1000), color: '#E91E63' }))
      .sort((a, b) => a.time - b.time)

    const selectedTrailerId = state.trailerData && state.trailerData.length > 0 ? state.trailerData[0].deviceId : null;

    this.truckData = (state.truckData || [])
      .map(d => ({ ...d, time: new Date(d.timeInMs * 1000), color: 'dodgerblue' }))
      .sort((a, b) => a.time - b.time)

    this.tripData = state.trips.map(m => ({
      ...m,
      startTime: new Date(m.tripStartTime),
      endTime: new Date(m.tripEndTime)
    }));

    this.data = this.trailerData.concat(this.truckData)

    /*this.trips = (state.trips || [])
      .map(d => ({ 
        ...d, 
        startTime: new Date(d.matchStartTime),
        endTime: new Date(d.matchLastTime)
      }))*/

    this.x = d3.scaleTime()
      .range([0, this.props.width])
      .domain(this.data ? d3.extent(this.data, d => d.time) : null)

    const ySpeed = d3.scaleLinear()
      .range([this.props.height, 0])
      .domain([0, 120])
      .nice()

    const area = (accessor, yScale) => d3.area()
      .curve(d3.curveStepAfter)
      .x(d => this.x(d.time))
      .y0(yScale(0))
      .y1(d => yScale(d[accessor]))

    const line = (accessor, yScale) => d3.line()
      .curve(d3.curveStepAfter)
      .x(d => this.x(d.time))
      .y(d => yScale(d[accessor]))

    const xAxis = d3
      .axisBottom(this.x)
      .tickFormat(d3.timeFormat("%H:%M"))

    const yLeftAxis = d3
      .axisLeft(ySpeed)

    yLeftAxis
      .ticks(5)

    const dataSeries = [
      {
        color: 'dodgerblue',
        accessor: 'speed',
        scale: ySpeed,
        data: this.truckData.filter(d => d.speed !== null && d.speed !== undefined),
      },
      {
        color: '#E91E63',
        accessor: 'speed',
        scale: ySpeed,
        data: this.trailerData.filter(d => d.speed !== null && d.speed !== undefined),
      },
    ];

    const areaCurve = this.areas
      .selectAll('.areaCurve')
      .data(dataSeries)

    const areaCurveEnter = areaCurve.enter()
      .append('path')
      .attr('class', 'areaCurve')

    areaCurveEnter.merge(areaCurve)
      .transition()
      .duration(750)
      .attr('fill', d => d.color)
      .attr('opacity', 0.1)
      .attr('d', d => area(d.accessor, d.scale)(d.data))

    const lineCurve = this.areas
      .selectAll('.lineCurve')
      .data(dataSeries)

    const lineCurveEnter = lineCurve.enter()
      .append('path')
      .attr('class', 'lineCurve')

    lineCurveEnter.merge(lineCurve)
      .transition()
      .duration(750)
      .attr('fill', 'none')
      .attr('stroke', d => d.color)
      .attr('stroke-width', 2)
      .attr('d', d => line(d.accessor, d.scale)(d.data))

    this.trips
      .selectAll('.trip')
      .data(this.tripData).enter()
      .append('line')
      .attr('class', 'trip')
      .attr("x1", d => this.x(d.startTime) || 0)
      .attr("y1", this.props.height + 31)
      .attr("x2", d => this.x(d.endTime) || 0)
      .attr("y2", this.props.height + 31)
      .attr("stroke", d => d.bestMatchTrailerId === selectedTrailerId ? "#E91E63" : "grey")
      .attr("stroke-width", 13)
      .attr("opacity", 0.6)
      .attr("stroke-linecap", d => d.bestMatchTrailerId ? "round" : "")
      .attr("stroke-dasharray", d => d.bestMatchTrailerId ? 0 : 1)
      .style("cursor", d => d.bestMatchTrailerId && d.bestMatchTrailerId !== selectedTrailerId ? "pointer" : "")
      .on("click", d => {
        if (state.onTripSelection) {
          state.onTripSelection(d);
        }
      });

    this.trips
      .selectAll('.tripNumber')
      .data(this.tripData).enter()
      .append('text')
      .attr('class', 'tripNumber')
      .attr("x", d => ((this.x(d.startTime) || 0) + (this.x(d.endTime) || 0)) / 2)
      .attr("y", this.props.height + 32)
      .attr('fill', '#272727')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-weight', 'bold')
      .text((d, i) => i+1);

    /*this.couplings
      .selectAll('.match')
      .data(this.trips).enter()
      .append('line')
      .attr('class', 'match')
      .attr("x1", d => this.x(d.startTime))
      .attr("y1", this.props.height + 30)
      .attr("x2", d => this.x(d.endTime))
      .attr("y2", this.props.height + 30)
      .attr("stroke", "dodgerblue")
      .attr("stroke-width", 12)
      .attr("stroke-linecap", "round")

    this.couplings
      .selectAll('.confidence')
      .data(this.trips).enter()
      .append('text')
      .attr('class', 'confidence')
      .attr("x", d => (this.x(d.startTime) + this.x(d.endTime)) / 2)
      .attr("y", this.props.height + 31)
      .attr('fill', 'white')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(d => Math.round(100 * d.confidence) / 100);*/

    const points = this.trailerData.concat(this.truckData)
    this.points.selectAll('.points')
      .data(points).enter()
      .append('line')
      .attr('class', 'points')
      .attr("x1", d => this.x(d.time))
      .attr("y1", this.props.height + 19)
      .attr("x2", d => this.x(d.time))
      .attr("y2", this.props.height + 23)
      .attr("stroke", d => d.color)
      .attr("stroke-width", 1)

    this.svg.select('.xAxis')
      .call(xAxis)
    this.svg.select('.trailerHistoryYAxisLeft')
      .call(yLeftAxis)
  }

  drawPin() {
    var self = this;
    this.pin.append('circle')
      .attr('r', 8)
      .attr('fill', 'white')
      .attr('stroke', 'grey')
      .attr('stroke-width', 3)
      .attr('cx', this.props.width)
      .attr('cy', this.props.height)
      .call(d3.drag()
        .on('start', function(d) { self.dragStarted(this) })
        .on('drag', function(d) { self.dragged(this, self) })
        .on('end', function(d) { self.dragEnded(this) }))
        .on('mouseover', function(d){ d3.select(this).style('cursor', 'ew-resize') });
  }

  dragStarted(element) {
    d3.select(element)
      .attr('fill', 'grey');
  }
  
  dragged(element, self) {
    const posX = Math.max(0, Math.min(d3.event.x, self.props.width))
    this.pin.select('circle')
      .attr('cx', posX);

    const time = self.x.invert(posX);
    const currentIndex = self.data.findIndex(d => d.time > time)
    if (self.onPosChange && currentIndex > 0) {
      self.onPosChange({ ...self.data[currentIndex - 1], time });
    } else {
      self.onPosChange({ time });
    }
  }
  
  dragEnded(element) {
    d3.select(element)
      .attr('fill', 'white');
  }
}
