import { Auth } from "aws-amplify";
import { push } from "connected-react-router";

import actionTypes from "../actions/actionTypes";
import actions from "../actions/index";
import constants from "../helpers/constants";

const { LOCAL_STORAGE_KEYS } = constants;

function wipeLocalStorage() {
  Object.keys(LOCAL_STORAGE_KEYS).forEach(keyToRemove => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS[keyToRemove]);
  });
}

const userMiddleware = store => next => action => {
  if (action.type === actionTypes.USER_SIGN_OUT) {
    next(action);
    wipeLocalStorage();
    store.dispatch(actions.customer.selectCustomer(null));
    store.dispatch(push("/"));
    return Auth.signOut();
  }
  if (action.type === actionTypes.USER_RETRIEVE_SESSION_FAILURE) {
    next(action);
    return store.dispatch(actions.user.signOut());
  }
  return next(action);
};

export default userMiddleware;
