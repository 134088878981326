import { Auth } from "aws-amplify";

import actionTypes from "./actionTypes";

function signOut() {
  return {
    type: actionTypes.USER_SIGN_OUT
  };
}

function retrieveSessionRequest() {
  return {
    type: actionTypes.USER_RETRIEVE_SESSION_REQUEST
  };
}

function retrieveSessionFailure(error) {
  return {
    type: actionTypes.USER_RETRIEVE_SESSION_FAILURE,
    error
  };
}

function retrieveSessionSuccess(session, user) {
  return {
    type: actionTypes.USER_RETRIEVE_SESSION_SUCCESS,
    session,
    user
  };
}

function retrieveSession() {
  return dispatch => {
    dispatch(retrieveSessionRequest());
    return Promise.all([Auth.currentSession(), Auth.currentUserInfo()]) // Issue with currentUserInfo: empty when using federated identity
      .then(([session, user]) => {
        return dispatch(retrieveSessionSuccess(session, user));
      })
      .catch(error => dispatch(retrieveSessionFailure(error)));
  };
}
export default {
  signOut,
  retrieveSession
};
