// @flow
const actionTypes = {
  TRUCK_TRAILER_MATCHINGS_FETCH_REQUEST:
    "TRUCK_TRAILER_MATCHINGS_FETCH_REQUEST",
  TRUCK_TRAILER_MATCHINGS_FETCH_SUCCESS:
    "TRUCK_TRAILER_MATCHINGS_FETCH_SUCCESS",
  TRUCK_TRAILER_MATCHINGS_FETCH_FAILURE:
    "TRUCK_TRAILER_MATCHINGS_FETCH_FAILURE",
  TRUCK_TRAILER_MATCHING_SELECT_TRUCK: "TRUCK_TRAILER_MATCHING_SELECT_TRUCK",
  TRUCK_TRAILER_MATCHING_SELECT_TRAILER:
    "TRAILER_TRAILER_MATCHING_SELECT_TRUCK",
  USER_SIGN_OUT: "USER_SIGN_OUT",
  USER_RETRIEVE_SESSION_REQUEST: "USER_RETRIEVE_SESSION_REQUEST",
  USER_RETRIEVE_SESSION_SUCCESS: "USER_RETRIEVE_SESSION_SUCCESS",
  USER_RETRIEVE_SESSION_FAILURE: "USER_RETRIEVE_SESSION_FAILURE",
  POSITION_HISTORY_TRUCK_FETCH_REQUEST: "POSITION_HISTORY_TRUCK_FETCH_REQUEST",
  POSITION_HISTORY_TRUCK_FETCH_SUCCESS: "POSITION_HISTORY_TRUCK_FETCH_SUCCESS",
  POSITION_HISTORY_TRUCK_FETCH_FAILURE: "POSITION_HISTORY_TRUCK_FETCH_FAILURE",
  POSITION_HISTORY_TRUCK_RESET: "POSITION_HISTORY_TRUCK_RESET",
  POSITION_HISTORY_TRAILER_FETCH_REQUEST:
    "POSITION_HISTORY_TRAILER_FETCH_REQUEST",
  POSITION_HISTORY_TRAILER_FETCH_SUCCESS:
    "POSITION_HISTORY_TRAILER_FETCH_SUCCESS",
  POSITION_HISTORY_TRAILER_FETCH_FAILURE:
    "POSITION_HISTORY_TRAILER_FETCH_FAILURE",
  POSITION_HISTORY_TRAILER_RESET: "POSITION_HISTORY_TRAILER_RESET",
  MESSAGE_DISPLAY_ONE: "MESSAGE_DISPLAY_ONE",

  GET_TRUCK_COUPLINGS_REQUEST: "GET_TRUCK_COUPLINGS_REQUEST",
  GET_TRUCK_COUPLINGS_SUCCESS: "GET_TRUCK_COUPLINGS_SUCCESS",
  GET_TRUCK_COUPLINGS_FAILURE: "GET_TRUCK_COUPLINGS_FAILURE",

  VALIDATE_COUPLING: "VALIDATE_COUPLING",
  RESET_VALIDATION: "RESET_VALIDATION",
  CUSTOMER_GET_ALL_REQUEST: "CUSTOMER_GET_ALL_REQUEST",
  CUSTOMER_GET_ALL_SUCCESS: "CUSTOMER_GET_ALL_SUCCESS",
  CUSTOMER_GET_ALL_FAILURE: "CUSTOMER_GET_ALL_FAILURE",
  CUSTOMER_SELECT_CUSTOMER: "CUSTOMER_SELECT_CUSTOMER",
  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  SKY_SYNC_REQUEST: "SKY_SYNC_REQUEST",
  SKY_SYNC_SUCCESS: "SKY_SYNC_SUCCESS",
  SKY_SYNC_FAILURE: "SKY_SYNC_FAILURE"
};

export default actionTypes;
