// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import moment from "moment";
import { Avatar, Empty } from "antd";

import actions from "../../actions";
import JetpackTable from "../JetpackComponents/JetpackTable/JetpackTable";
import CouplingSummaryChart from "../../charts/CouplingSummaryChart/CouplingSummaryChartComponent";

function colorCircle(action) {
  if (action === "Couple") {
    return "black";
  }
  if (action === "Uncouple") {
    return "white";
  }
  if (action === "Delete") {
    return "#e84343";
  }

  return "#4670f2";
}

class TruckCouplingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // eslint-disable-next-line class-methods-use-this
  renderExpanded(truck) {
    const trailerColorMap = truck.trailerColorMap || {};
    const data = truck.apiCalls;
    if (!data) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    const columns = [
      {
        title: "Date",
        dataIndex: "timeOfExecution",
        sorterType: "dateobj",
        render: text => {
          return moment(text).format("MMM D - HH:mm:ss.SSS");
        },
        defaultSortOrder: "descend"
      },
      {
        title: "Trailer",
        dataIndex: "trailerPlate",
        filterType: "search",
        render: (val, record) => {
          const id = record.skyAPIevent && record.skyAPIevent.trailer;
          const color = trailerColorMap[id];
          if (color) {
            return <strong style={{ color }}>{val}</strong>;
          }
          return val;
        }
      },
      {
        title: "Action",
        dataIndex: "action",
        filterType: "categorial",
        defaultFilteredValue: ["Couple", "Uncouple"],
        render: action => {
          const style = {
            backgroundColor: colorCircle(action),
            border: colorCircle(action) === "white" ? "1px solid black" : "",
            marginRight: 8
          };
          return (
            <span>
              <Avatar size={8} style={style} />
              {action}
            </span>
          );
        }
      },
      {
        title: "Action Trigger",
        dataIndex: "apiTriggerEvent"
      }
    ];
    return (
      <JetpackTable
        size="small"
        data={data}
        columns={columns}
        rowKey={record =>
          `${+record.timeOfExecution}${record.apiTriggerEvent}${
            record.lastOdoOfCoupling
          }`
        }
        style={{ paddingRight: 50, paddingTop: 16 }}
      />
    );
  }

  render() {
    const { trucks } = this.props;
    const columns = [
      {
        title: "Truck",
        dataIndex: "truckPlate",
        key: "truckPlate",
        sorterType: "text",
        filterType: "search"
      },
      {
        title: "Coupling (Odo based – last 2000 Km)",
        dataIndex: "couplings",
        key: "odoCouplings",
        render: (couplings, truck) => {
          const end = d3.max(
            couplings.filter(c => c.startOdo !== "None" && c.endOdo !== "None"),
            c => c.endOdo
          );
          const start = end - 2000;
          return (
            <CouplingSummaryChart
              width={400}
              couplings={couplings}
              type="odo"
              start={start}
              end={end}
              startLabel="2000 km"
              endLabel="0 km"
              apiCalls={truck.apiCalls}
              trailerColorMap={truck.trailerColorMap}
            />
          );
        }
      },
      {
        title: "Coupling (Time based – last 3 days)",
        dataIndex: "couplings",
        key: "timeCouplings",
        render: (couplings, truck) => {
          const end = moment().toDate();
          const start = moment()
            .subtract(3, "days")
            .toDate();
          return (
            <CouplingSummaryChart
              width={400}
              couplings={couplings}
              type="time"
              start={start}
              end={end}
              startLabel="3 days ago"
              endLabel="Today"
              apiCalls={truck.apiCalls}
              trailerColorMap={truck.trailerColorMap}
            />
          );
        }
      }
    ];
    return (
      <div>
        <JetpackTable
          size="small"
          data={trucks}
          columns={columns}
          pagination={{ pageSize: 20 }}
          rowKey="truckPlate"
          expandedRowRender={truck => this.renderExpanded(truck)}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    selectTruckId: truckId => dispatch(actions.matchings.selectTruck(truckId)),
    selectTrailerId: trailerId =>
      dispatch(actions.matchings.selectTrailer(trailerId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckCouplingTable);
