import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";

import actions from "../../actions";

const { Option } = Select;

function customers(state) {
  if (state.customer.customers.items) {
    return state.customer.customers.items.sort((a,b) => {
      if (a.fleetName.toLowerCase() > b.fleetName.toLowerCase()) {
        return 1;
      }
      return -1;
    });
  }
  return []
}
function selectedCustomer(state) {
  return state.customer.selectedCustomer;
}

function displayCustomer({ fleetCode, fleetName }) {
  return `${fleetName} (${fleetCode})`
}

function FleetSelector() {
  const availableCustomers = useSelector(customers);
  const currentCustomer = useSelector(selectedCustomer);
  const dispatch = useDispatch();
  const selectCustomer = customerId => {
    const customer = availableCustomers.find(
      d => Number(d.fleetCode) === Number(customerId)
    );
    dispatch(actions.customer.selectCustomer(customer));
  };
  if (!availableCustomers) {
    return null;
  }
  if (availableCustomers.length === 0) {
    return null;
  }
  return (
        <Select
          showSearch
          value={currentCustomer && currentCustomer.fleetCode}
          style={{ width: 200, backgroundColor: "white", marginRight: 12 }}
          placeholder="Select a fleet"
          onChange={selectCustomer}
          filterOption={(input, option) => {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        >
          {availableCustomers.map((cust,index) => (
            <Option key={index} value={cust.fleetCode}>
              {displayCustomer(cust)}
            </Option>
          ))}
        </Select>
  );
}

export default FleetSelector;
