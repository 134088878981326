import React from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import { paths } from "../../routes";

const { Item } = Menu;

const NavigationMenu = ({ currentPath, collapsed }) => (
  <Menu
    mode="inline"
    selectedKeys={[currentPath]}
    inlineCollapsed={collapsed}
    style={{ borderRight: collapsed ? "1px solid #e8e8e8" : "none" }} // right border fix
  >
    {Object.keys(paths)
      .filter(key => !paths[key].hideFromMenu)
      .map(pathKey => (
        <Item title={paths[pathKey].title} key={pathKey}>
          <Link to={paths[pathKey].path}>
            <Icon type={paths[pathKey].icon} />
            <span>{paths[pathKey].title}</span>
          </Link>
        </Item>
      ))}
  </Menu>
);

export default NavigationMenu;
